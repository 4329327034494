import { gql, useApolloClient, useQuery } from "@apollo/client";
import {
  AppBar,
  Button,
  Container,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import SettingsSystemDaydreamRoundedIcon from "@material-ui/icons/SettingsSystemDaydreamRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { navigate } from "@reach/router";
import React from "react";
import csIcon from "../assets/logo/csLogo.svg";
import { useRootStore } from "../mobx/context";
const useStyles = makeStyles((theme) => ({
  appBar: {
    marginBottom: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: 16,
  },
  csIcon: {
    width: 50,
    height: "auto",
    marginRight: theme.spacing(2),
  },
}));

// Authenticated pages may be wrapped within this layout (for instance dashboard layout)
export const AuthenticatedLayout = ({ children }) => {
  const classes = useStyles();
  const rootStore = useRootStore();
  const client = useApolloClient();
  return (
    <Container>
      <AppBar
        position="static"
        className={classes.appBar}
        style={{ display: "flex", flexGrow: 1 }}
        elevation={0}
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
          >
            <Grid item>
              <img src={csIcon} className={classes.csIcon} />
            </Grid>
            <Grid item>
              <Hidden smDown>
                <Typography variant="overline" className={classes.title}>
                  CounterSketch Streaming
                </Typography>
              </Hidden>
            </Grid>
            <Grid item style={{ marginLeft: "auto" }}>
              <Button
                color="inherit"
                startIcon={<HomeRoundedIcon />}
                onClick={() => {
                  navigate("/");
                }}
              >
                Home
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="inherit"
                startIcon={<SettingsSystemDaydreamRoundedIcon />}
                onClick={() => {
                  navigate("manageFiles");
                }}
              >
                Manage Files
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{ color: "inherit" }}
                onClick={() => {
                  client.clearStore();
                  rootStore.AuthenticationStore.logout();
                  rootStore.resetAllStores();
                  navigate("login");
                }}
                startIcon={<ExitToAppIcon />}
              >
                {`Logout`}
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {children}
    </Container>
  );
};

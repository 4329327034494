import dayjs from "dayjs";
import _ from "lodash";
import { makeAutoObservable, toJS } from "mobx";
import { RootStore } from "./rootStore";
export type ArchiveDownloadObject = {
  userID: string;
  archiveID: string;
  archiveName: string;
  downloadKey: string;
  downloaded: boolean;
  archiveStatus: string;
  ttl: number;
  downloadProgress: number;
  downloading: boolean;
};
export type ArchiveDownloadObjectCollection = {
  [x: string]: ArchiveDownloadObject;
};
export default class ArchiveDownloadStore {
  private root: RootStore;
  private archiveDownloadCollectionIds = Array<string>();
  private activeDownloadObjects: ArchiveDownloadObjectCollection;
  constructor(root: RootStore) {
    this.root = root;
    this.archiveDownloadCollectionIds = [];
    this.activeDownloadObjects = {};
    makeAutoObservable(this);
  }
  resetStore = () => {
    this.archiveDownloadCollectionIds = [];
    this.activeDownloadObjects = {};
  };

  mergeNewDataWithOld = (newData: Array<ArchiveDownloadObject>) => {
    //prepare the new data
    const newArchiveCollectionObjects = _.map(newData, (item) => {
      return {
        [item.archiveID]: {
          ...item,

          downloadProgress:
            this.activeDownloadObjects[item.archiveID]?.downloadProgress || 0,
          downloading:
            this.activeDownloadObjects[item.archiveID]?.downloading || false,
        },
      };
    });

    this.activeDownloadObjects = Object.assign(
      this.activeDownloadObjects,
      ...newArchiveCollectionObjects
    );
  };

  setArchiveDownloadItemDownloadProgress = (
    archiveID: string,
    progress: number
  ) => {
    this.activeDownloadObjects[archiveID].downloadProgress = progress;
  };

  setArchiveDownloadItemDownloadStatus = (
    archiveID: string,
    status: boolean
  ) => {
    this.activeDownloadObjects[archiveID].downloading = status;
  };

  getArchiveDownloadItemObject = (archiveID: string) => {
    return this.activeDownloadObjects[archiveID];
  };

  getActiveDownloadObjects = () => {
    //map to array
    const objKeys = _.keysIn(this.activeDownloadObjects);
    const objectArray: Array<ArchiveDownloadObject> = _.map(objKeys, (obj) => {
      return this.activeDownloadObjects[obj];
    });
    console.log(objectArray);
    //sort the objects by newest first
    const sortedFiles = objectArray
      ?.sort((a, b) => (dayjs.unix(a.ttl).isAfter(dayjs.unix(b.ttl)) ? 1 : -1))
      .reverse();

    return sortedFiles;
  };
}

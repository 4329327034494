import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { observable, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useRootStore } from "../../../../mobx/context";
import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { Alert } from "@material-ui/lab";
const CREATE_ARCHIVE = gql`
  mutation CreateArchive(
    $accessToken: String!
    $keys: [String!]!
    $archiveName: String!
  ) {
    createArchive(
      accessToken: $accessToken
      keys: $keys
      archiveName: $archiveName
    ) {
      statusCode
    }
  }
`;
export const ArchiveNameDialogComponent = observer(() => {
  const { ArchiveStore, AuthenticationStore } = useRootStore();
  const client = useApolloClient();
  const [dialogState] = useState(() =>
    observable({
      open: false,
      archiveName: "",
      handleArchiveNameChange: (value: string) => {
        dialogState.archiveName = value;
      },

      handleClose: () => {
        dialogState.open = false;
      },
      handleOpen: () => {
        dialogState.open = true;
      },
    })
  );
  const handleCreateArchive = async () => {
    try {
      //converts all selected key hashes into s3 object keys
      const keysArray = runInAction(() => {
        return ArchiveStore.getKeysFromSelected();
      });
      if (keysArray.length > 0 && dialogState.archiveName !== "") {
        client
          .mutate({
            mutation: CREATE_ARCHIVE,
            variables: {
              accessToken: AuthenticationStore.getAccessTokenValue(),
              keys: keysArray,
              archiveName: dialogState.archiveName,
            },
          })
          .then()
          .catch((e) => {
            console.log(e);
          });

        dialogState.archiveName = "";
        dialogState.handleClose();
      }
      runInAction(() => {
        ArchiveStore.deselectAllFiles();
      });
    } catch (err) {
      console.log("MODAL", err.message);
    }
  };

  return (
    <React.Fragment>
      <Button
        style={{ width: "100%" }}
        size="large"
        variant="outlined"
        onClick={dialogState.handleOpen}
        disabled={
          ArchiveStore.selectedFilesCount() === 0 ||
          ArchiveStore.doesArchiveExceedMaxSize
        }
      >
        Create Archive
      </Button>

      <Dialog
        open={dialogState.open}
        onClose={dialogState.handleClose}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{"Name Package"}</DialogTitle>
        <DialogContent>
          <TextField
            id="filled-basic"
            label="Package Name"
            variant="outlined"
            fullWidth
            value={dialogState.archiveName}
            onChange={(e) => {
              dialogState.handleArchiveNameChange(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogState.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={async (e) => {
              e.preventDefault();
              await handleCreateArchive();
            }}
            color="primary"
            autoFocus
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

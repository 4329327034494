import {
  Breadcrumbs,
  Grid,
  LinearProgress,
  Link,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { KeyboardReturnOutlined } from "@material-ui/icons";
import _ from "lodash";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useRootStore } from "../../../mobx/context";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["200"],
    minHeight: 45,
    maxHeight: 45,
  },
}));
const FileDirectoryInformationComponent = observer(() => {
  const classes = useStyles();
  const { ArchiveStore, FileStore } = useRootStore();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-around" direction="row">
        <Grid item xs={4}>
          <Typography variant="overline" style={{ fontSize: 10 }}>
            Selected Files: {ArchiveStore.selectedFilesCount()}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Tooltip
            title={
              <Typography variant="caption">
                {FileStore.getCurrentFolderName()}
              </Typography>
            }
            arrow
          >
            <Typography
              variant="overline"
              noWrap
              display="block"
              style={{ fontSize: 10 }}
            >
              Folder: {FileStore.getCurrentFolderName()}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
});

export default FileDirectoryInformationComponent;

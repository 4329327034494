import {
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import FolderOpenOutlined from "@material-ui/icons/FolderOpenOutlined";
import { observer } from "mobx-react-lite";
import * as React from "react";
type FolderListItemComponentProps = {
  handleClick: (any) => any;
  folderName: string;
};
const FolderListItemComponent: React.FunctionComponent<FolderListItemComponentProps> =
  observer(({ handleClick, folderName }) => {
    return (
      <ListItem role={undefined} dense button onClick={handleClick}>
        <ListItemIcon>
          <FolderOpenOutlined color="secondary" fontSize="large" />
        </ListItemIcon>
        <ListItemText id={"key"} primary={folderName} />
      </ListItem>
    );
  });

export default FolderListItemComponent;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect } from "react";
import { navigate, useLocation } from "@reach/router";
import { useRootStore } from "./mobx/context";
import Routes from "./routes";
import { gql, useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { observer } from "mobx-react-lite";
import { ILoginResponse } from "./interfaces/gql/login";
import FullPageLoading from "./components/fullPageLoading/fullPageLoading";
import { runInAction } from "mobx";
import queryString from "query-string";
//gql query for logging a user in via their creds.
const LOGIN_WITH_TOKEN = gql`
  query login($refreshToken: String!) {
    loginWithToken(refreshToken: $refreshToken) {
      AccessToken
      ExpiresIn
      RefreshToken
    }
  }
`;
const App = observer(() => {
  ("App re-render...");
  const { AuthenticationStore } = useRootStore();
  const client = useApolloClient();
  const refreshTokenStoredValue = localStorage.getItem("streamingUserToken");
  const wasUserLoggedIn = localStorage.getItem("streamingUserWasLoggedIn");
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    if (location.search) {
      if (location.pathname === "/join") {
        console.log("path");
      }
    } else {
      //user is already logged in, redirect to application.
      if (wasUserLoggedIn && refreshTokenStoredValue) {
        setLoading(true);
        client
          .query({
            query: LOGIN_WITH_TOKEN,
            variables: {
              refreshToken: refreshTokenStoredValue,
            },
            fetchPolicy: "no-cache",
          })
          .then((response) => {
            const { loginWithToken } = response.data;
            const authResults = loginWithToken as ILoginResponse;
            runInAction(() => {
              AuthenticationStore.setRefreshToken(
                refreshTokenStoredValue || "undefined"
              );
              AuthenticationStore.setAccessToken(authResults.AccessToken);
              AuthenticationStore.setAccessTokenExpirationTime(
                authResults.ExpiresIn
              );
              AuthenticationStore.setUserAuthentication(true);
            });
            setLoading(false);
            if (location.pathname !== "/login") {
              console.log(location.pathname);
              navigate(location.pathname);
            }
          })
          .catch((error) => {
            console.log("LOGINERROR", error);
            navigate("/login");
            setLoading(false);
          });
      } else {
        setLoading(false);
        //all else fails, navigate to login page.
        navigate("/login");
      }
    }
  }, []);

  return (
    <div>
      {loading ? <FullPageLoading message={"Please Wait"} /> : <Routes />}
    </div>
  );
});

export default App;

import * as React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react-lite";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import BackupRoundedIcon from "@material-ui/icons/BackupRounded";
import FileUploadComponent from "./fileUploadComponent";
import { useRootStore } from "../../../../mobx/context";
import { ApolloClient, gql, useApolloClient } from "@apollo/client";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Color } from "@material-ui/lab/Alert/Alert";
import { useToasts } from "react-toast-notifications";
const GENERATE_UPLOAD_URL = gql`
  query generateUploadURL($accessToken: String!, $filePath: String!) {
    generateUploadURL(accessToken: $accessToken, filePath: $filePath) {
      url
    }
  }
`;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  menuButton: {},
}));
//Regex String for checking the validity of uuid v4
const v4RegEx = new RegExp(
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
);

const FileUploadModalComponent = observer(() => {
  const classes = useStyles();
  const client = useApolloClient();
  const { AuthenticationStore, FileStore } = useRootStore();
  const [uploadFile, setUploadFile] = React.useState<FileList>();
  const [uploading, setUploading] = React.useState(false);
  const [disableUploadButton, setDisableUploadButton] = React.useState(false);
  const [showBadProjectsPathAlert, setShowBadProjectsPathAlert] =
    React.useState(false);
  const [uploadingProgress, setUploadingProgress] = React.useState(0);
  const { addToast } = useToasts();

  // limits uploads by filepath, prior to doing the valid file extension check.
  const setUploadFileWithFilePathCheck = (uploadFile: FileList) => {
    //get extension, csg files can only be uploaded into a projects sub directory
    const fileExtension = uploadFile[0].name.split(".").pop();
    console.log(fileExtension);
    let isInProjectsDirectory = false;
    switch (fileExtension) {
      case "csg":
        isInProjectsDirectory = FileStore.getCurrentPath().includes("Projects");
        if (
          !isInProjectsDirectory ||
          FileStore.getCurrentPath().split("/").length <= 3
        ) {
          //shows alert message error, about csgs required to be in projects subfolder
          setShowBadProjectsPathAlert(true);
          setDisableUploadButton(true);
          return;
        }
        break;
    }
    setShowBadProjectsPathAlert(false);
    setDisableUploadButton(false);
    setUploadFile(uploadFile);
  };
  const [dialogState] = React.useState(() =>
    observable({
      open: false,
      archiveName: "",
      handleArchiveNameChange: (value: string) => {
        dialogState.archiveName = value;
      },

      handleClose: (event, reason) => {
        if (reason !== "backdropClick") {
          dialogState.open = false;
        }
      },
      handleOpen: () => {
        dialogState.open = true;
      },
      forceClose: () => {
        dialogState.open = false;
      },
    })
  );

  const handleUploadFile = async () => {
    try {
      let parsedFilename = "";
      if (uploadFile) {
        //make filename changes to align with what countersketch expects.
        //uuid_uuid, first is parent document id, second is revision id.
        //update the revision id, if the filename does not follow this pattern, just change it to a uuid.csg
        const splitFilename = uploadFile[0].name.split("_");
        //get extension, csg files can only be uploaded into a projects sub directory
        const fileExtension = uploadFile[0].name.split(".").pop();
        console.log(splitFilename);
        console.log(fileExtension);

        if (v4RegEx.test(splitFilename[0])) {
          // uuid_uuid.csg format throw away second half and generate a new uuid.csg
          const childUuid = uuidv4() + ".csg";
          //join the original parent uuid, with the new child uuid to complete the new filename
          parsedFilename = [splitFilename[0], childUuid].join("_");
        } else {
          if (uploadFile[0].name.split(".").pop() === "csg") {
            const filenameUUID = uuidv4();
            const newUuidFilename = `${filenameUUID}_${filenameUUID}.csg`;
            parsedFilename = newUuidFilename;
          } else {
            parsedFilename = uploadFile[0].name;
          }
        }
        const { data } = await client.query({
          query: GENERATE_UPLOAD_URL,
          fetchPolicy: "network-only",
          variables: {
            accessToken: AuthenticationStore.getAccessTokenValue(),
            filePath: `${FileStore.getCurrentPath()}${parsedFilename}`,
          },
        });
        if (data) {
          const signedURL = data.generateUploadURL.url;
          setUploading(true);
          await axios.put(signedURL, uploadFile[0], {
            onUploadProgress: (progress) => {
              const totalDone = (progress.loaded / progress.total) * 100;
              setUploadingProgress(totalDone);
            },
          });

          addToast(
            `Successfully Uploaded: ${uploadFile[0].name} as ${parsedFilename}`,
            {
              appearance: "success",
            }
          );

          setUploading(false);
          setUploadingProgress(0);
          setUploadFile(undefined);
          dialogState.open = false;
        }
      }
    } catch (error) {
      console.log(error);
      addToast(`Failed to  upload, please try again.`, {
        appearance: "error",
      });
      setUploading(false);
      setUploadingProgress(0);
      setUploadFile(undefined);
      dialogState.open = false;
    }
  };

  return (
    <React.Fragment>
      <Tooltip
        title="Upload File To Current Directory"
        placement="bottom"
        arrow
      >
        <span>
          <Button
            color="primary"
            variant="outlined"
            className={classes.menuButton}
            onClick={() => {
              dialogState.handleOpen();
              setUploadFile(null);
              setShowBadProjectsPathAlert(false);
              setDisableUploadButton(false);
            }}
            startIcon={<BackupRoundedIcon />}
          >
            Upload File
          </Button>
        </span>
      </Tooltip>

      <Dialog
        open={dialogState.open}
        onClose={(event, reason) => dialogState.handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="overline" color="secondary">
            File Upload{" "}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {uploading ? (
            <LinearProgress variant="determinate" value={uploadingProgress} />
          ) : (
            <FileUploadComponent
              handleDialogClose={dialogState.forceClose}
              handleUploadFile={handleUploadFile}
              setUploadFile={setUploadFileWithFilePathCheck}
              uploadFile={uploadFile}
              showBadCsgPathAlert={showBadProjectsPathAlert}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={dialogState.forceClose}
            color="primary"
            disabled={uploading}
          >
            Cancel
          </Button>
          <Button
            onClick={async (e) => {
              e.preventDefault();
              await handleUploadFile();
            }}
            color="primary"
            autoFocus
            disabled={uploading || disableUploadButton}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default FileUploadModalComponent;

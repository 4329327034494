import { gql, useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useToasts } from "react-toast-notifications";
import { useRootStore } from "../../mobx/context";
import DividerWithTextComponent from "../dividerWithText/dividerWithTextComponent";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";

import CheckIcon from "@material-ui/icons/Check";
import { Alert } from "@material-ui/lab";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
const QUERY_CLAIMS_AMOUNT = gql`
  query csStreamingLicenseCount($accessToken: String!) {
    csStreamingLicenseCount(accessToken: $accessToken) {
      total
      used
      isTrial
      trialExpiration
    }
  }
`;

const USER_HAS_ACITVE_CLAIM = gql`
  query userHasActiveCsStreamingClaim($accessToken: String!) {
    userHasActiveCsStreamingClaim(accessToken: $accessToken) {
      claim
    }
  }
`;

const CLAIM_LICENSE = gql`
  query claimStreamingLicense($accessToken: String!) {
    claimStreamingLicense(accessToken: $accessToken) {
      isValidClaim
      expirationTime
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
  },
  title: {
    textAlign: "center",
  },
  licensePool: {
    textAlign: "center",
  },
  userAvatar: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
  cardTitle: {
    fontSize: "24px",
  },
}));
const CSStreamingLaunch = observer(() => {
  const classes = useStyles();
  const client = useApolloClient();
  const { addToast } = useToasts();
  const { AuthenticationStore, StreamingStore } = useRootStore();
  const [availableClaims, setAvailableClaims] = React.useState(0);
  const [usedClaims, setUsedClaims] = React.useState(0);
  const [userActiveClaim, setUserAcitveClaim] = React.useState(false);
  const [launchButtonState, setLaunchButtonState] = React.useState("FULL");
  const { data, loading, error } = useQuery(QUERY_CLAIMS_AMOUNT, {
    variables: { accessToken: AuthenticationStore.getAccessTokenValue() },
    pollInterval: 2000,
    fetchPolicy: "network-only",
  });

  const {
    data: activeClaimData,
    loading: activeClaimLoading,
    error: activeClaimError,
  } = useQuery(USER_HAS_ACITVE_CLAIM, {
    variables: { accessToken: AuthenticationStore.getAccessTokenValue() },
    pollInterval: 2000,
    fetchPolicy: "network-only",
  });
  if (activeClaimError) {
    console.log("ActiveClaimError", activeClaimError);
  }

  const launchDisabled = () => {
    if (
      !userActiveClaim &&
      usedClaims === availableClaims &&
      availableClaims !== 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const launchButtonRenderState = (state: string) => {
    switch (state) {
      case "TRIAL":
        return (
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            style={{ height: "75px", marginBottom: 12 }}
            onClick={() => {
              client
                .query({
                  query: CLAIM_LICENSE,
                  variables: {
                    accessToken: AuthenticationStore.getAccessTokenValue(),
                  },
                  fetchPolicy: "no-cache",
                })
                .then((response) => {
                  const { claimStreamingLicense } = response.data;
                  //console.log(claimStreamingLicense);
                  if (claimStreamingLicense.isValidClaim) {
                    window.open("streaming");
                  } else {
                    addToast("Failed to reserve license", {
                      appearance: "error",
                    });
                  }
                })
                .catch((error) => {
                  addToast(error.message, {
                    appearance: "error",
                  });
                });
            }}
          >
            Launch Streaming Trial
          </Button>
        );
      case "PURCHASE":
        return (
          <Button
            onClick={() => {
              window.open("https://gemvision.com/countersketch");
            }}
          >
            Buy CounterSketch
          </Button>
        );
      case "UNKNOWN":
        return (
          <Button
            disabled={true}
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            style={{ height: "75px", marginBottom: 12 }}
          >
            Please Wait..
          </Button>
        );
      case "FULL":
        return (
          <Button
            disabled={launchDisabled()}
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            style={{ height: "75px", marginBottom: 12 }}
            onClick={() => {
              client
                .query({
                  query: CLAIM_LICENSE,
                  variables: {
                    accessToken: AuthenticationStore.getAccessTokenValue(),
                  },
                  fetchPolicy: "no-cache",
                })
                .then((response) => {
                  const { claimStreamingLicense } = response.data;
                  //console.log(claimStreamingLicense);
                  if (claimStreamingLicense.isValidClaim) {
                    window.open("streaming");
                  } else {
                    addToast("Failed to reserve license", {
                      appearance: "error",
                    });
                  }
                })
                .catch((error) => {
                  addToast(error.message, {
                    appearance: "error",
                  });
                });
            }}
          >
            Launch Streaming
          </Button>
        );
    }
  };

  React.useEffect(() => {
    setLaunchButtonState("UNKNOWN");
    if (data) {
      const { csStreamingLicenseCount } = data;
      //console.log(csStreamingLicenseCount);
      if (csStreamingLicenseCount.isTrial) {
        if (
          dayjs().isAfter(dayjs.unix(csStreamingLicenseCount.trialExpiration))
        ) {
          setLaunchButtonState("FULL");
        } else {
          setLaunchButtonState("TRIAL");
        }
      } else {
        setLaunchButtonState("FULL");
        setAvailableClaims(csStreamingLicenseCount.total);
        setUsedClaims(csStreamingLicenseCount.used);
      }
    }
    if (activeClaimData) {
      const { userHasActiveCsStreamingClaim } = activeClaimData;
      //console.log(userHasActiveCsStreamingClaim);
      if (userHasActiveCsStreamingClaim.claim !== null) {
        setUserAcitveClaim(true);
      } else {
        setUserAcitveClaim(false);
      }
    }
  }, [data, setAvailableClaims, setUsedClaims, activeClaimData]);
  if (error) {
    console.log(error);
  }

  return (
    <div className={classes.root}>
      <Card variant="outlined">
        <CardHeader
          className={classes.cardTitle}
          avatar={
            <Avatar className={classes.userAvatar}>
              <AccountCircleRoundedIcon />
            </Avatar>
          }
          title={
            <Typography variant="subtitle1">
              {AuthenticationStore.getStoredUsername}
            </Typography>
          }
          subheader={
            launchButtonState === "TRIAL"
              ? `Trial expires ${dayjs().to(
                  dayjs.unix(data.csStreamingLicenseCount.trialExpiration)
                )}`
              : `License Available: ${availableClaims - usedClaims}`
          }
        />

        <CardContent>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            direction="row"
            spacing={0}
          >
            <Grid item xs={12}>
              {launchButtonRenderState(launchButtonState)}
            </Grid>
            <CardActionArea>
              <Box hidden={!userActiveClaim}>
                <Alert>License Active</Alert>
              </Box>
            </CardActionArea>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
});

export default CSStreamingLaunch;

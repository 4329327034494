import { makeAutoObservable } from "mobx";
import ArchiveDownloadStore from "./archiveDownloadStore";
import ArchiveStore from "./archiveStore";
import { AuthenticationStore } from "./authenticationStore";
import { FileStore } from "./fileStore";
import StreamingStore from "./streamingStore";

export class RootStore {
  AuthenticationStore: AuthenticationStore;
  FileStore: FileStore;
  ArchiveStore: ArchiveStore;
  ArchiveDownloadStore: ArchiveDownloadStore;
  StreamingStore: StreamingStore;
  constructor() {
    this.AuthenticationStore = new AuthenticationStore(this);
    this.FileStore = new FileStore(this);
    this.ArchiveStore = new ArchiveStore(this);
    this.ArchiveDownloadStore = new ArchiveDownloadStore(this);
    this.StreamingStore = new StreamingStore(this);
    makeAutoObservable(this);
  }
  resetAllStores = () => {
    this.AuthenticationStore = new AuthenticationStore(this);
    this.FileStore = new FileStore(this);
    this.ArchiveStore = new ArchiveStore(this);
    this.ArchiveDownloadStore = new ArchiveDownloadStore(this);
    this.StreamingStore = new StreamingStore(this);
  };
}

import { useApolloClient } from "@apollo/client";
import { makeStyles } from "@material-ui/core";

import React from "react";

import { useRootStore } from "../mobx/context";
const useStyles = makeStyles((theme) => ({
  appBar: {
    marginBottom: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: 16,
  },
  csIcon: {
    width: 50,
    height: "auto",
    marginRight: theme.spacing(2),
  },
}));

// Authenticated pages may be wrapped within this layout (for instance dashboard layout)
export const SharedStreamingLayout = ({ children }) => {
  const classes = useStyles();
  const rootStore = useRootStore();
  const client = useApolloClient();
  return <div style={{ pointerEvents: "none" }}>{children}</div>;
};

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  CircularProgress,
  Grid,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import dayjs from "dayjs";
import { autorun, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { FunctionComponent, useEffect } from "react";
import { useRootStore } from "../../../mobx/context";
import { FolderType } from "../../../mobx/stores/fileStore";
import DividerWithTextComponent from "../../dividerWithText/dividerWithTextComponent";
import FullPageLoading from "../../fullPageLoading/fullPageLoading";

import FileListItemComponent from "./fileListItem";
import Skeleton from "@material-ui/lab/Skeleton";
import FolderListItemComponent from "../folderComponents/folderListItem";
import EmptyFileFolderListItemComponent from "../emptyFileFolderListItemComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
  },
  list: {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
    maxHeight: 500,
    minHeight: 500,
  },
  progressWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    height: "100%",
  },
}));

type FileDirectoryListComponentProps = {
  loading: boolean;
};
const FileDirectoryListComponent: FunctionComponent<FileDirectoryListComponentProps> =
  observer(({ loading }) => {
    const classes = useStyles();

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Grid container justifyContent="flex-start">
            <Grid item xs={12}>
              <div className={classes.list}>
                {loading ? (
                  <div className={classes.progressWrapper}>
                    <CircularProgress size={90} />
                  </div>
                ) : (
                  <List>{createFilesAndFoldersFromPath()}</List>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  });

const createFilesAndFoldersFromPath = () => {
  const { FileStore } = useRootStore();
  if (FileStore.isEmptyFileDirectory()) {
    return [
      <EmptyFileFolderListItemComponent
        key="emptyFile"
        title={"No Files In Directory"}
        message={""}
        subMessage={`Would you like to upload a file to this the "${FileStore.getCurrentFolderName()}" folder?`}
        displayUploadButton
      />,
    ];
  }
  //sort the files by newest first
  const sortedFiles = FileStore.getFileObjectsForPath(FileStore.currentPath)
    ?.sort((a, b) =>
      dayjs(a.lastModified).isAfter(dayjs(b.lastModified)) ? 1 : -1
    )
    .reverse();

  const files = _.map(sortedFiles, (file) => {
    return (
      <FileListItemComponent
        key={file.uniqueKey}
        fileName={file.fileName}
        fileSize={file.size}
        uniqueKey={file.uniqueKey}
        lastModified={file.lastModified}
        pathKey={file.key}
      />
    );
  });
  return [...files];
};

export default FileDirectoryListComponent;

import {
  AppBar,
  Button,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  IconButton,
  makeStyles,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import MailRoundedIcon from "@material-ui/icons/MailRounded";
import CloseIcon from "@material-ui/icons/Close";
import { navigate } from "@reach/router";
import React from "react";
import csIcon from "../assets/logo/csLogo.svg";
import { useRootStore } from "../mobx/context";
import { gql, useLazyQuery } from "@apollo/client";
import FullPageLoading from "../components/fullPageLoading/fullPageLoading";
import { FunctionComponent } from "react";
import { useToasts } from "react-toast-notifications";
const END_STREAMING_SESSION = gql`
  query endCsStreamingSession($accessToken: String!, $username: String!) {
    endCsStreamingSession(accessToken: $accessToken, username: $username) {
      status
    }
  }
`;
const USER_HAS_ACITVE_CLAIM = gql`
  query userHasActiveCsStreamingClaim($accessToken: String!) {
    userHasActiveCsStreamingClaim(accessToken: $accessToken) {
      claim
    }
  }
`;
const CREATE_STREAMING_SESSION = gql`
  query startCsStreamingSession(
    $accessToken: String!
    $username: String!
    $password: String!
  ) {
    startCsStreamingSession(
      accessToken: $accessToken
      username: $username
      password: $password
    ) {
      url
      expiresIn
    }
  }
`;
const INVITE_SESSION = gql`
  query shareStreamingSession(
    $accessToken: String!
    $email: String!
    $sessionUrl: String!
  ) {
    shareStreamingSession(
      accessToken: $accessToken
      email: $email
      sessionUrl: $sessionUrl
    ) {
      status
    }
  }
`;
const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 1,
    top: "auto",
    bottom: 0,
  },
  menuButton: {
    "&:hover, &.Mui-focusVisible": {
      backgroundColor: theme.palette.primary.light,
    },
    display: "inline-block",
    position: "absolute",
    left: "50%",
    top: "96.5%",
    zIndex: 99,
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    transition: "top 0.3s ease, backgroundColor 0.3s ease, color 0.3s ease",
  },
  menuButtonClose: {
    "&:hover, &.Mui-focusVisible": { backgroundColor: theme.palette.grey[100] },
    display: "inline-block",
    position: "absolute",
    left: "50%",
    top: "91%",
    zIndex: 99,
    backgroundColor: theme.palette.grey[300],
    transitionAll: "top 1s ease, backgroundColor 0.3s ease, color 0.3s ease",
  },
  title: {
    flexGrow: 1,
    fontSize: 18,
  },
  csIcon: {
    width: 50,
    height: "auto",
    marginRight: theme.spacing(2),
  },
}));
// Authenticated pages may be wrapped within this layout (for instance dashboard layout)
export const StreamingLayout = ({ children }) => {
  const classes = useStyles();
  const { AuthenticationStore } = useRootStore();
  const { addToast } = useToasts();
  const [expanded, setExpanded] = React.useState(false);
  const [endingSession, setEndingSession] = React.useState(false);
  const [shareSessionEmail, setShareSessionEmail] = React.useState("");
  const [shareSessionDialogOpen, setShareSessionDialogOpen] =
    React.useState(false);
  const expandCollapseMenu = () => {
    setExpanded(!expanded);
  };
  const [endStreamingSession, { data, loading }] = useLazyQuery(
    END_STREAMING_SESSION,
    { fetchPolicy: "network-only" }
  );

  const [
    shareStreamingSession,
    { data: shareStreamingSessionData, error: shareStreamingSessionError },
  ] = useLazyQuery(INVITE_SESSION, {
    fetchPolicy: "network-only",
  });
  const [
    createStreamingSession,
    { data: createStreamingSessionData, error: createStreamingSessionError },
  ] = useLazyQuery(CREATE_STREAMING_SESSION, {
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if (createStreamingSessionData) {
      const { startCsStreamingSession } = createStreamingSessionData;
      console.log(startCsStreamingSession);
      shareStreamingSession({
        variables: {
          accessToken: AuthenticationStore.getAccessTokenValue(),
          email: shareSessionEmail,
          sessionUrl: startCsStreamingSession.url,
        },
      });
    }
  }, [createStreamingSessionData]);

  React.useEffect(() => {
    if (shareStreamingSessionData) {
      const { shareStreamingSession } = shareStreamingSessionData;
      if (shareStreamingSession.status === 200) {
        addToast(`Invite Sent: ${shareSessionEmail}`, {
          appearance: "success",
        });
      }
    }
  }, [shareStreamingSessionData]);
  const clearStreamingLaunchTime = () => {
    localStorage.removeItem("appStreamLaunched");
  };
  if (data) {
    clearStreamingLaunchTime();
    window.close();
  }

  const handleShareDialogClose = () => {
    setShareSessionDialogOpen(false);
  };
  const handleShareDialogOpen = () => {
    setShareSessionEmail("");
    setExpanded(false);
    setShareSessionDialogOpen(true);
  };
  const handleShareEmailChange = (value: string) => {
    setShareSessionEmail(value);
  };

  const handleShareSession = () => {
    handleShareDialogClose();
    createStreamingSession({
      variables: {
        accessToken: AuthenticationStore.getAccessTokenValue(),
        username: AuthenticationStore.getStoredUsername,
        password: AuthenticationStore.getUserFingerPrint,
      },
    });
  };
  return (
    <div>
      <ShareSessionDialog
        shareDialogOpen={shareSessionDialogOpen}
        handleDialogClose={handleShareDialogClose}
        email={shareSessionEmail}
        handleEmail={handleShareEmailChange}
        handleShareSession={handleShareSession}
      />
      <IconButton
        size="small"
        className={expanded ? classes.menuButtonClose : classes.menuButton}
        onClick={() => {
          expandCollapseMenu();
        }}
      >
        {expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
      </IconButton>
      <Fade in={expanded} unmountOnExit>
        <AppBar position="absolute" className={classes.appBar}>
          <Toolbar>
            <Button
              variant="contained"
              size="small"
              startIcon={<CloseIcon />}
              onClick={(e) => {
                e.preventDefault();
                setEndingSession(true);
                setExpanded(false);
                endStreamingSession({
                  variables: {
                    accessToken: AuthenticationStore.getAccessTokenValue(),
                    username: AuthenticationStore.getStoredUsername,
                  },
                });
              }}
            >
              End Session
            </Button>
            <Button
              variant="contained"
              size="small"
              startIcon={<MailRoundedIcon />}
              style={{ marginLeft: "14px" }}
              onClick={(e) => {
                e.preventDefault();
                handleShareDialogOpen();
              }}
            >
              Invite
            </Button>
          </Toolbar>
        </AppBar>
      </Fade>

      {endingSession ? <FullPageLoading message="Ending Session" /> : children}
    </div>
  );
};

interface ShareSessionDialogProps {
  shareDialogOpen: boolean;
  handleDialogClose: () => void;
  email: string;
  handleEmail: (value: string) => void;
  handleShareSession: () => void;
}
const ShareSessionDialog: FunctionComponent<ShareSessionDialogProps> = ({
  shareDialogOpen,
  handleDialogClose,
  email,
  handleEmail,
  handleShareSession,
}) => {
  return (
    <div>
      <Dialog
        open={shareDialogOpen}
        onClose={() => handleDialogClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Invite User To Session</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To invite another user to this session, please enter their email
            address here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => {
              handleEmail(e.target.value);
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose()} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleShareSession();
            }}
            color="primary"
          >
            Invite
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

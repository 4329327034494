import { gql, useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useToasts } from "react-toast-notifications";
import { useRootStore } from "../../mobx/context";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useLocation } from "@reach/router";
declare let appstreamEmbed: any;
declare let AppStream: any;
import queryString from "query-string";
const FETCH_INVITE_STREAMING_INFO = gql`
  query joinCssStreamingSession($sessionID: String!) {
    joinCssStreamingSession(sessionID: $sessionID) {
      url
    }
  }
`;
const SharedStreamingClientComponent = observer(() => {
  const { AuthenticationStore } = useRootStore();
  let appstreamEmbed: any;
  const { addToast } = useToasts();
  const client = useApolloClient();
  const location = useLocation();
  const [streamingEmbedVisibility, setStreamingEmbedVisibility] =
    React.useState<VisibilityState>("visible");
  const [sessionIDFound, setSessionIDFound] = React.useState(false);
  const [sharedSessionStarted, setSharedSessionStarted] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");
  React.useEffect(() => {
    if (location.search && !sharedSessionStarted) {
      console.log("SEARCH", location.search);
      const queryObj = queryString.parse(location.search);

      //sharedSessionID
      if (queryObj.sharedSessionID) {
        console.log("found session..");
        console.log(queryObj.sharedSessionID);
        setSharedSessionStarted(true);
        launchAppStreamSession(queryObj.sharedSessionID as string);
      } else {
        console.log("no valid session.");
        setAlertMessage("Invalid, or expired streaming session link.");
        setAlertTitle("Streaming Session Not Found");
        setStreamingEmbedVisibility("hidden");
      }
    }
  });
  const launchAppStreamSession = (sessionID: string) => {
    client
      .query({
        query: FETCH_INVITE_STREAMING_INFO,
        variables: {
          sessionID: sessionID,
        },
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        console.log(response);
        const { joinCssStreamingSession } = response.data;
        if (
          joinCssStreamingSession.url &&
          joinCssStreamingSession.url.length > 0
        ) {
          //destroyAppStreamFrame();
          const userInterfaceConfig = {};
          userInterfaceConfig[AppStream.Embed.Options.HIDDEN_ELEMENTS] = [
            AppStream.Embed.Elements.TOOLBAR,
          ];
          const appstreamOptions = {
            sessionURL: joinCssStreamingSession.url,
            userInterfaceConfig: userInterfaceConfig,
          };
          addToast("Starting your session, please wait.", {
            appearance: "info",
          });

          appstreamEmbed = new AppStream.Embed(
            "appstream-container",
            appstreamOptions
          );

          appstreamEmbed.addEventListener(
            AppStream.Embed.Events.SESSION_ERROR,
            errorCallback
          );
        }
      })
      .catch((error) => {
        setAlertMessage("Invalid, or expired streaming session link.");
        setAlertTitle("Streaming Session Not Found");
        setStreamingEmbedVisibility("hidden");
      });
  };
  function errorCallback(event) {
    console.log("APPSTREAMERROR", JSON.stringify(event));
    setStreamingEmbedVisibility("hidden");
    //destroyAppStreamFrame();
  }
  const destroyAppStreamFrame = () => {
    if (appstreamEmbed) {
      console.log("DESTROY START");
      appstreamEmbed.destroy();
      appstreamEmbed = null;

      const rootElement = document.getElementById("root");
      const oldContainer = document.getElementById("appstream-container");
      rootElement.removeChild(oldContainer);
      console.log("DESTROY END");
    }
  };

  //console.log(appstreamEmbed);
  return (
    <div id="root" style={{ overflow: "hidden" }}>
      <div
        id="appstream-container"
        style={{
          visibility: streamingEmbedVisibility,
          width: "100vw",
          height: "100vh",
          margin: "auto",
          minWidth: "640px",
          minHeight: "525px",
        }}
      >
        <Box hidden={streamingEmbedVisibility === "hidden" ? false : true}>
          <Grid
            container
            direction="row"
            alignItems="center"
            alignContent="center"
            justifyContent="space-around"
            style={{
              visibility:
                streamingEmbedVisibility === "visible" ? "collapse" : "visible",
            }}
          >
            <Grid item xs={12} style={{ marginBottom: "12px" }}>
              <Alert severity="error">
                <AlertTitle>{alertTitle}</AlertTitle>
                {alertMessage}
              </Alert>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
});

export default SharedStreamingClientComponent;

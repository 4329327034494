import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StoreProvider } from "./mobx/context";
import { ThemeProvider } from "@material-ui/core";
import { counterSketchTheme } from "./theme";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { ToastProvider } from "react-toast-notifications";
import {
  createMemorySource,
  createHistory,
  LocationProvider,
} from "@reach/router";

//setup the apollo ApolloClient
const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_GATEWAY_URL,
  cache: new InMemoryCache(),
});
ReactDOM.render(
  <React.StrictMode>
    <LocationProvider>
      <ToastProvider autoDismiss>
        <ThemeProvider theme={counterSketchTheme}>
          <StoreProvider>
            <ApolloProvider client={apolloClient}>
              <App />
            </ApolloProvider>
          </StoreProvider>
        </ThemeProvider>
      </ToastProvider>
    </LocationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

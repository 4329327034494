import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import ClearIcon from "@material-ui/icons/Clear";
import * as React from "react";
import ArchiveRoundedIcon from "@material-ui/icons/ArchiveRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { useRootStore } from "../../../../mobx/context";
import { gql, useApolloClient, useLazyQuery } from "@apollo/client";
import axios from "axios";
import { constants } from "buffer";
import { FunctionComponent, useState } from "react";
import { runInAction } from "mobx";
import ArchiveDownloadStore from "../../../../mobx/stores/archiveDownloadStore";
const DOWNLOAD_ARCHIVE = gql`
  query downloadArchive($accessToken: String!, $archiveID: String!) {
    downloadArchive(accessToken: $accessToken, archiveID: $archiveID) {
      url
    }
  }
`;
const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),

    marginRight: theme.spacing(1),
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
}));
type ArchiveDownloadListItemComponentProps = {
  archiveID: string;
};
const ArchiveDownloadListItemComponent: React.FunctionComponent<ArchiveDownloadListItemComponentProps> =
  ({ archiveID }) => {
    // const [downloadArchive, { data }] = useLazyQuery(DOWNLOAD_ARCHIVE, {
    //   fetchPolicy: "cache-and-network",
    // });
    const client = useApolloClient();

    const { AuthenticationStore, ArchiveDownloadStore } = useRootStore();
    const classes = useStyles();

    const onClickDownloadArchive = async () => {
      try {
        runInAction(() => {
          ArchiveDownloadStore.setArchiveDownloadItemDownloadStatus(
            archiveID,
            true
          );
        });
        const { data } = await client.query({
          query: DOWNLOAD_ARCHIVE,
          variables: {
            accessToken: AuthenticationStore.getAccessTokenValue(),
            archiveID: archiveID,
          },
        });

        if (data) {
          getFileToDownload(data.downloadArchive.url).then((response) => {
            const type = response.headers["content-type"];
            const blob = new Blob([response.data], {
              type: type,
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =
              ArchiveDownloadStore.getArchiveDownloadItemObject(
                archiveID
              ).archiveName;
            link.click();
            runInAction(() => {
              ArchiveDownloadStore.setArchiveDownloadItemDownloadProgress(
                archiveID,
                0
              );
              ArchiveDownloadStore.setArchiveDownloadItemDownloadStatus(
                archiveID,
                false
              );
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    const getFileToDownload = (url: string) => {
      return axios.get(url, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
        onDownloadProgress: (progess) => {
          runInAction(() => {
            const progress = (progess.loaded / progess.total) * 100;
            ArchiveDownloadStore.setArchiveDownloadItemDownloadProgress(
              archiveID,
              progress
            );
          });
        },
      });
    };

    return (
      <ListItem role={undefined} dense>
        <ListItemAvatar>
          <Badge
            badgeContent={
              ArchiveDownloadStore.getArchiveDownloadItemObject(archiveID)
                .downloaded
                ? null
                : "New"
            }
            color="error"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Avatar
              variant="rounded"
              className={classes.largeAvatar}
              color="primary"
            >
              <ArchiveRoundedIcon fontSize="large" />
            </Avatar>
          </Badge>
        </ListItemAvatar>
        <ListItemText
          id={
            ArchiveDownloadStore.getArchiveDownloadItemObject(archiveID)
              .archiveName
          }
          primary={
            ArchiveDownloadStore.getArchiveDownloadItemObject(archiveID)
              .archiveName
          }
          secondary={`Expires: ${dayjs
            .unix(
              ArchiveDownloadStore.getArchiveDownloadItemObject(archiveID).ttl
            )
            .format("L LT")}`}
        />
        <ListItemSecondaryAction>
          {ArchiveDownloadStore.getArchiveDownloadItemObject(archiveID)
            .archiveStatus === "Processing" ? (
            <CircularProgress />
          ) : (
            <ArchiveDownloadButton
              archiveID={archiveID}
              handleDownload={async () => {
                await onClickDownloadArchive();
              }}
            />
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

interface ArchiveDownloadButtonProps {
  archiveID: string;
  handleDownload: () => void;
}
const ArchiveDownloadButton: FunctionComponent<ArchiveDownloadButtonProps> =
  observer(({ archiveID, handleDownload }) => {
    const { ArchiveDownloadStore } = useRootStore();
    return (
      <div style={{ display: "flex", flexGrow: 1 }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={12}>
            <IconButton
              disabled={
                ArchiveDownloadStore.getArchiveDownloadItemObject(archiveID)
                  .downloading
              }
              onClick={() => {
                if (
                  ArchiveDownloadStore.getArchiveDownloadItemObject(archiveID)
                    .downloading
                ) {
                  return;
                }
                handleDownload();
              }}
            >
              {ArchiveDownloadStore.getArchiveDownloadItemObject(archiveID)
                .downloading ? (
                <LinearProgress
                  variant="determinate"
                  value={
                    ArchiveDownloadStore.getArchiveDownloadItemObject(archiveID)
                      .downloadProgress
                  }
                  style={{ width: 90, height: 10 }}
                />
              ) : (
                <GetAppRoundedIcon color="primary" />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  });

export default ArchiveDownloadListItemComponent;

/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { RootStore } from "./stores/rootStore";
const StoreContext = React.createContext<RootStore | undefined>(undefined);
let rootStoreInstance: RootStore;
const StoreProvider: React.FC = ({ children }) => {
  //singleton rootstore instance for react hot reloading.
  if (!rootStoreInstance) {
    rootStoreInstance = new RootStore();
  }
  return (
    <StoreContext.Provider value={rootStoreInstance}>
      {children}
    </StoreContext.Provider>
  );
};

const useRootStore = () => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error("useRootStore must be used within RootStoreProvider");
  }
  return context;
};

export { StoreProvider, useRootStore };

import React, { FunctionComponent } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
export interface IFullPageLoadingProps {
  message: string;
}
export const FullPageLoading: FunctionComponent<IFullPageLoadingProps> = ({
  message,
}) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <CircularProgress size={80} />
      <h3>{message}</h3>
    </Grid>
  );
};

export default FullPageLoading;

import {
  Avatar,
  Checkbox,
  CircularProgress,
  Grid,
  Grow,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import DescriptionIcon from "@material-ui/icons/Description";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useRootStore } from "../../../mobx/context";
import { FunctionComponent } from "react";
import { gql, useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import { runInAction } from "mobx";
import { useToasts } from "react-toast-notifications";
import ReactImageMagnify from "react-image-magnify";
const PREVIEW_IMAGE_URL = gql`
  query generatePreviewImage($accessToken: String!, $filePath: String!) {
    generatePreviewImage(accessToken: $accessToken, filePath: $filePath) {
      url
    }
  }
`;
const GENERATE_DOWNLOAD_URL = gql`
  query generateSignedUrlForFile($accessToken: String!, $filePath: String!) {
    generateSignedUrlForFile(accessToken: $accessToken, filePath: $filePath) {
      url
    }
  }
`;
dayjs.extend(localizedFormat);
const useStyles = makeStyles((theme) => ({
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(1),
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
  largeAvatarPreview: {
    marginRight: theme.spacing(1),
  },
}));
type FileListItemComponentProps = {
  fileName: string;
  fileSize: number;
  uniqueKey: string;
  lastModified: string;
  pathKey: string;
};
const FileListItemComponent: React.FunctionComponent<FileListItemComponentProps> =
  observer(({ fileName, fileSize, uniqueKey, lastModified, pathKey }) => {
    const { ArchiveStore } = useRootStore();

    const handleFileSelect = (key: string) => {
      ArchiveStore.selectFile(key);
    };
    const fileSelectedValue = (key: string) => {
      return ArchiveStore.isFileSelected(key);
    };

    return (
      <ListItem
        button
        onClick={(e) => {
          e.preventDefault();
          handleFileSelect(uniqueKey);
        }}
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={fileSelectedValue(uniqueKey)}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": fileName }}
          />
        </ListItemIcon>
        <ListItemAvatar>
          <FileListItemPreviewAvatarComponent pathKey={pathKey} />
        </ListItemAvatar>

        <ListItemText
          id={fileName}
          primary={fileName}
          secondary={
            <FileListItemSecondaryText
              fileSize={fileSize}
              lastModified={lastModified}
            />
          }
        />
        <ListItemSecondaryAction>
          <FileListItemDownloadButton
            pathKey={pathKey}
            fileName={fileName}
            uniqueKey={uniqueKey}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );
  });
type FileListItemSecondaryTextProps = {
  fileSize: number;
  lastModified: string;
};
const FileListItemSecondaryText: FunctionComponent<FileListItemSecondaryTextProps> =
  observer(({ fileSize, lastModified }) => {
    return (
      <div style={{ display: "flex" }}>
        <Grid container justifyContent="flex-start" direction="row">
          <Grid item xs={12}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              Modified:
              {dayjs(lastModified).format("L LT")}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  });
interface FileListItemPreviewAvatarProps {
  pathKey: string;
}
const FileListItemPreviewAvatarComponent: FunctionComponent<FileListItemPreviewAvatarProps> =
  observer(({ pathKey }) => {
    const classes = useStyles();
    const client = useApolloClient();
    const { AuthenticationStore } = useRootStore();
    const [imageData, setImageData] = React.useState("");
    const [previewLoading, setPreviewLoading] = React.useState(false);
    React.useEffect(() => {
      if (imageData === "") {
        fetchPreviewImage();
      }
    }, [imageData]);
    const fetchPreviewImage = async () => {
      setPreviewLoading(true);
      client
        .query({
          query: PREVIEW_IMAGE_URL,
          variables: {
            accessToken: AuthenticationStore.getAccessTokenValue(),
            filePath: pathKey,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          if (response.data.generatePreviewImage.url !== "") {
            setImageData(response.data.generatePreviewImage.url);
          }
          setPreviewLoading(false);
        })
        .catch((error) => {
          console.log(pathKey, error);
        });
    };
    return (
      <React.Fragment>
        {previewLoading ? (
          <Skeleton
            animation="wave"
            variant="rect"
            style={{ marginRight: 9, borderRadius: 5 }}
            width={55}
            height={55}
          />
        ) : imageData ? (
          // <Avatar
          //   src={`data:image/png;base64,${imageData}`}
          //   variant="rounded"
          //   className={classes.largeAvatarPreview}
          // />
          <ReactImageMagnify
            enlargedImageContainerDimensions={{
              width: "250px",
              height: "180px",
            }}
            enlargedImageContainerStyle={{
              zIndex: 98,

              borderWidth: "4px",
              height: "100%",
            }}
            enlargedImageStyle={{
              backgroundColor: "white",
              zIndex: 100,
              position: "relative",
              width: "100%",
              height: "100%",
            }}
            imageStyle={{
              paddingRight: "10px",
              width: "40px",
              borderRadius: "5px",
              borderWidth: "4px",
            }}
            {...{
              smallImage: {
                src: `data:image/png;base64,${imageData}`,
                width: "70",
                height: "56",
              },
              largeImage: {
                alt: "",
                src: `data:image/png;base64,${imageData}`,
                width: "100%",
                height: "auto",
              },
            }}
          />
        ) : (
          <Avatar
            variant="rounded"
            className={classes.largeAvatar}
            color="primary"
          >
            <DescriptionIcon fontSize="large" />
          </Avatar>
        )}
      </React.Fragment>
    );
  });
interface FileListItemDownloadButtonProps {
  pathKey: string;
  fileName: string;
  uniqueKey: string;
}
const FileListItemDownloadButton: FunctionComponent<FileListItemDownloadButtonProps> =
  observer(({ pathKey, fileName, uniqueKey }) => {
    const { AuthenticationStore, FileStore } = useRootStore();
    const client = useApolloClient();
    const { addToast } = useToasts();
    const downloadFile = () => {
      runInAction(() => {
        FileStore.updateFileDownloadStatus(uniqueKey, true);
      });
      client
        .query({
          query: GENERATE_DOWNLOAD_URL,
          variables: {
            accessToken: AuthenticationStore.getAccessTokenValue(),
            filePath: pathKey,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          console.log(response);
          const signedURL = response.data.generateSignedUrlForFile.url;
          axios
            .get(signedURL, {
              responseType: "arraybuffer",
              headers: {
                "Content-Type": "application/json",
              },
              onDownloadProgress: (progess) => {
                runInAction(() => {
                  const progress = (progess.loaded / progess.total) * 100;
                  FileStore.updateFileDownloadStatus(uniqueKey, true, progress);
                });
              },
            })
            .then((response) => {
              const type = response.headers["content-type"];
              const blob = new Blob([response.data], {
                type: type,
              });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              runInAction(() => {
                FileStore.updateFileDownloadStatus(uniqueKey, false, 0);
              });
              addToast(`Downloaded: ${fileName}`, { appearance: "success" });
            });
        })
        .catch((error) => {
          console.log(error);
          addToast(`Failed to download ${fileName}`, { appearance: "error" });
          runInAction(() => {
            FileStore.updateFileDownloadStatus(uniqueKey, false, 0);
          });
        });
    };
    return (
      <React.Fragment>
        {FileStore.getFileObjectByKey(uniqueKey).downloading ? (
          <CircularProgress
            variant={
              FileStore.getFileObjectByKey(uniqueKey).downloadProgress !== 0
                ? "determinate"
                : "indeterminate"
            }
            value={FileStore.getFileObjectByKey(uniqueKey).downloadProgress}
          />
        ) : (
          <IconButton
            edge="end"
            aria-label="download"
            onClick={() => downloadFile()}
          >
            <GetAppRoundedIcon color="primary" />
          </IconButton>
        )}
      </React.Fragment>
    );
  });

export default FileListItemComponent;

import {
  Box,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
  withStyles,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import green from "@material-ui/core/colors/green";
import * as React from "react";
import { useRootStore } from "../../../../mobx/context";
const ArchiveSizeLinearProgress = withStyles((theme) => ({
  root: {
    height: 12,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey["300"],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["100"],
    minHeight: 45,
  },
  fileSizeProgressDark: {
    color: "black",
  },
  fileSizeProgressLight: {
    color: "white",
  },
}));
const SelectedFilesArchiveSizeComponent = observer(() => {
  const classes = useStyles();
  const { ArchiveStore } = useRootStore();

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems="center"
        spacing={0}
        justifyContent="space-evenly"
      >
        <Grid item xs={4}>
          <Typography variant="overline" style={{ marginLeft: 5 }}>
            Archive Size:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="overline">
            {formatBytes(ArchiveStore.selectedBytes)}/
            {formatBytes(ArchiveStore.maxArchiveSize)}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
});
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
export default SelectedFilesArchiveSizeComponent;

import dayjs from "dayjs";
import { makeAutoObservable } from "mobx";
import { RootStore } from "./rootStore";

export default class StreamingStore {
  private rootStore: RootStore;
  private streamingSession: string;
  private urlExiprationTime: string;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.streamingSession = "asd";
    this.urlExiprationTime = "";
    makeAutoObservable(this);
  }
  get getStreamingSessionURL() {
    return this.streamingSession;
  }
  setStreamingSessionURL = (url: string, expires: string) => {
    this.streamingSession = url;
    this.urlExiprationTime = expires;
  };
}

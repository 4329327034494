import React from "react";
import { Router } from "@reach/router";
import LoginContainer from "../containers/login/loginContainer";
import {
  PublicRoute,
  PrivateRoute,
  StreamingRoute,
  SharedStreamingRoute,
} from "./helpers";
import DashboardComponent from "../components/dashboard/dashboardComponent";
import StreamingClientComponent from "../components/streamingClient/streamingClientComponent";
import FileDirectoryComponent from "../components/fileDirectory/directoryComponent";
import SharedStreamingClientComponent from "../components/streamingClient/sharedStreamingClientComponent";

const Routes = () => {
  return (
    <div>
      <Router>
        {/* <PrivateRoute component={TodoList} path="/" />
         */}
        <SharedStreamingRoute
          component={SharedStreamingClientComponent}
          path="/join"
        />
        <PublicRoute component={LoginContainer} path="login" />
        <PrivateRoute component={DashboardComponent} path="/" exact="true" />
        <PrivateRoute component={FileDirectoryComponent} path="manageFiles" />
        <StreamingRoute component={StreamingClientComponent} path="streaming" />
      </Router>
    </div>
  );
};

export default Routes;

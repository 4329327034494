import React, { FunctionComponent, useEffect, useState } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useRootStore } from "../../mobx/context";
import FileDirectoryListComponent from "./fileComponents/fileDirectoryListComponent";
import {
  AppBar,
  Badge,
  Box,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Typography,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import FileDirectoryTopMenuComponent from "./subMenus/fileDirectorySubMenuComponent";
import { observable, runInAction } from "mobx";
import DividerWithTextComponent from "../dividerWithText/dividerWithTextComponent";
import _ from "lodash";
import FileListItemComponent from "./fileComponents/fileListItem";
import FolderListItemComponent from "./folderComponents/folderListItem";
import FolderDirectoryListComponent from "./folderComponents/folderDirectoryListComponent";
import SelectedFilesComponent from "./archiveComponents/archiveBuilder/selectedFilesComponent";
import SelectedFilesArchiveSizeComponent from "./archiveComponents/archiveBuilder/selectedFilesArchiveSizeComponent";
import FolderDirectoryBreadcrumbComponent from "./folderComponents/folderDirectoryBreadcrumbComponent";
import FileDirectoryInformationComponent from "./fileComponents/fileDirectoryInformationComponent";
import { Tabs } from "@material-ui/core";
import FolderDirectoryTopMenuComponent from "./subMenus/fileDirectorySubMenuComponent";
import SelectedFilesSideMenuComponent from "./archiveComponents/selectedFilesSideMenuComponent";
import ArchiveDownloadListComponent from "./archiveComponents/archiveDownloader/archiveDownloadListComponent";

const NAVIGATE_DIRECTORY = gql`
  query navigateDirectory($accessToken: String!, $path: String!) {
    navigateDirectory(accessToken: $accessToken, path: $path) {
      files {
        fileName
        key
        size
        lastModified
      }
      folders {
        folderPath
        folderName
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  paper: {
    minHeight: 300,
  },
  control: {
    padding: theme.spacing(1),
  },
}));

const FileDirectoryComponent: React.FC = observer(({ children }) => {
  const classes = useStyles();
  const { AuthenticationStore, FileStore } = useRootStore();
  const { loading, data, error } = useQuery(NAVIGATE_DIRECTORY, {
    variables: {
      accessToken: AuthenticationStore.getAccessTokenValue(),
      path: FileStore.currentPath,
    },
    fetchPolicy: "cache-and-network",
    pollInterval: 2000,
  });
  useEffect(() => {
    if (data) {
      runInAction(() => {
        //AuthenticationStore.getAccessTokenValueTest();
        const navData = data.navigateDirectory;
        FileStore.formatPathsForDirectoryHash(navData.folders, navData.files);
      });
    }
    if (error) {
      console.log(error);
    }
  }, [data]);
  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item xs={12}>
          <FileDirectoryMenuTabComponent fileFolderLoading={loading} />
        </Grid>
      </Grid>
    </div>
  );
});

type FileDirectoryMenuTabComponentProps = {
  fileFolderLoading: boolean;
};

const FileDirectoryMenuTabComponent: FunctionComponent<FileDirectoryMenuTabComponentProps> =
  observer(({ fileFolderLoading }) => {
    const { ArchiveStore } = useRootStore();
    const classes = useStyles();
    const [tabs] = useState(() =>
      observable({
        tabValue: 0,
        handleChange: (event: React.ChangeEvent<unknown>, newValue: number) => {
          tabs.tabValue = newValue;
        },
      })
    );
    return (
      <React.Fragment>
        <AppBar
          position="static"
          color="secondary"
          style={{ marginTop: -16 }}
          elevation={1}
        >
          <Tabs value={tabs.tabValue} onChange={tabs.handleChange}>
            <Tab label="File Browser" />

            <Tab
              label={
                ArchiveStore.selectedFileIds.length > 0 ? (
                  <Badge
                    badgeContent={ArchiveStore.selectedFileIds.length}
                    color="error"
                  >
                    Package Manager
                  </Badge>
                ) : (
                  "Package Manager"
                )
              }
            />
          </Tabs>
        </AppBar>
        <TabPanel index={0} value={tabs.tabValue}>
          <div className={classes.root}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FolderDirectoryTopMenuComponent />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FolderDirectoryBreadcrumbComponent />
                <FolderDirectoryListComponent loading={fileFolderLoading} />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FileDirectoryInformationComponent />

                <FileDirectoryListComponent loading={fileFolderLoading} />
              </Grid>
            </Grid>
          </div>
        </TabPanel>
        <TabPanel index={1} value={tabs.tabValue}>
          <div className={classes.root}>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item xs={12}>
                <SelectedFilesSideMenuComponent />
              </Grid>
              <Grid item xs={6}>
                <SelectedFilesArchiveSizeComponent />
                <SelectedFilesComponent />
              </Grid>
              <Grid item xs={6}>
                <ArchiveDownloadListComponent />
              </Grid>
            </Grid>
          </div>
        </TabPanel>
      </React.Fragment>
    );
  });

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: FunctionComponent<TabPanelProps> = observer(
  ({ children, index, value }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (
          <Box p={2}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
);

export default FileDirectoryComponent;

import React, { FunctionComponent } from "react";
import {
  Checkbox,
  Button,
  Container,
  CssBaseline,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import csIcon from "../../assets/logo/csLogo.svg";
import blue from "@material-ui/core/colors/blue";
export interface ILoginComponentProps {
  getUsername: () => string;
  getStoredUsername: () => string;
  setUsername: (username: string) => void;
  getPassword: () => string;
  setPassword: (password: string) => void;
  loading: boolean;
  loginUser: () => void;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  csIcon: {
    width: "75px",
    height: "auto",
    marginBottom: theme.spacing(3),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: blue["100"],
    position: "absolute",
    top: "55%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const LoginComponent: FunctionComponent<ILoginComponentProps> = observer(
  ({
    getUsername,
    getStoredUsername,
    getPassword,
    setUsername,
    setPassword,
    loading,
    loginUser,
  }) => {
    const classes = useStyles();
    React.useEffect(() => {
      if (getUsername() === "") {
        setUsername(getStoredUsername());
      }
    }, [getStoredUsername]);
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={csIcon} className={classes.csIcon} />

          <Typography component="h1" variant="h5">
            CounterSketch Streaming
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              value={getUsername()}
              onChange={(event) => {
                event.persist();

                setUsername(event.target.value.toLowerCase());
              }}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              value={getPassword()}
              onChange={(event) => {
                event.persist();
                setPassword(event.target.value);
              }}
            />

            <div className={classes.wrapper}>
              <Button
                disabled={getUsername() === "" && getPassword() === ""}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={async (event) => {
                  event.preventDefault();
                  await loginUser();
                }}
              >
                Sign In
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </form>
        </div>
      </Container>
    );
  }
);

export default LoginComponent;

import { createTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import indigo from "@material-ui/core/colors/indigo";
export const counterSketchTheme = createTheme({
  palette: {
    primary: {
      main: "#3f6cb4",
    },
    background: {
      default: "#f9f9f9",
    },
    secondary: {
      main: "#4f8fe3",
    },
  },
});

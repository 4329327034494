import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { navigate } from "@reach/router";
import { useRootStore } from "../../mobx/context";
import SelectedFilesComponent from "../fileDirectory/archiveComponents/archiveBuilder/selectedFilesComponent";
import FileDirectoryComponent from "../fileDirectory/directoryComponent";
import FileUploadModalComponent from "../fileDirectory/fileComponents/fileUploads/fileUploadComponent";
import CSStreamingLaunch from "./csStreamingLaunch";
import NewsCardComponent from "../news/newsCardComponent";
import DividerWithTextComponent from "../dividerWithText/dividerWithTextComponent";
import NewsListComponent from "../news/newsListComponent";
import { Alert } from "@material-ui/lab";
import { isSafari } from "react-device-detect";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  rootGrid: {
    flexGrow: 1,
  },
  paper: {
    padding: 10,
  },
  control: {
    padding: theme.spacing(2),
  },
}));
const DashboardComponent = observer(() => {
  const classes = useStyles();
  const { AuthenticationStore } = useRootStore();
  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={2}>
        <Box hidden={!isSafari}>
          <Grid item xs={12}>
            <Alert severity="warning" style={{ minWidth: "84vw" }}>
              Looks like you are using Safari, we recommend FireFox, Chrome, or
              Edge browsers for the best experience.
            </Alert>
          </Grid>
        </Box>
        <Grid item xs={8}>
          <NewsListComponent />
        </Grid>
        <Grid item xs={4}>
          <CSStreamingLaunch />
        </Grid>
      </Grid>
    </div>
  );
});

export default DashboardComponent;

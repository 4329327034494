import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import dayjs from "dayjs";
import { grey } from "@material-ui/core/colors";
export interface NewsCardComponentProps {
  published_at: string;
  excerpt: string;
  feature_image: string;
  html: string;
  title: string;
  id: string;
}
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    borderRadius: "5px",
  },
  media: {
    height: 140,
  },
  readingMedia: {
    maskImage:
      "linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 50%, transparent 100%)",
  },
}));

const NewsCardComponent: FunctionComponent<NewsCardComponentProps> = observer(
  ({ published_at, excerpt, feature_image, html, title }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const onReadMoreClose = () => {
      setOpen(false);
    };
    return (
      <Grid item xs={12}>
        <ReadMoreDialog
          feature_image={feature_image}
          title={title}
          open={open}
          onClose={onReadMoreClose}
          html={html}
        />
        <Card variant="outlined" className={classes.root} elevation={0}>
          <CardActionArea
            onClick={() => {
              setOpen(true);
            }}
          >
            <CardMedia
              className={classes.media}
              image={feature_image}
              title={title}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                color="primary"
              >
                {title}
              </Typography>
              <Typography
                gutterBottom
                variant="caption"
                color="textSecondary"
                component="p"
              >
                Published {dayjs(published_at).format("MM.DD.YYYY")}
              </Typography>
              <Typography variant="body2" color="textPrimary" component="p">
                {excerpt}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  }
);

export interface ReadMoreDialogProps {
  open: boolean;
  title: string;
  html: string;
  feature_image: string;
  onClose: () => void;
}

const ReadMoreDialog: FunctionComponent<ReadMoreDialogProps> = ({
  open,
  title,
  html,
  feature_image,
  onClose,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="md"
      scroll="paper"
    >
      <DialogTitle id="simple-dialog-title" color="primary">
        <Typography color="primary" variant="subtitle1">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers={false}>
        <div className={classes.readingMedia}>
          <CardMedia
            image={feature_image}
            title={title}
            style={{ height: "200px" }}
          />
        </div>
        <DialogContentText
          id="simple-dialog-content"
          style={{ maxHeight: "200px", padding: "20px" }}
        >
          <div dangerouslySetInnerHTML={{ __html: html + "<br/>" }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewsCardComponent;

import React from "react";
import {
  Grid,
  Button,
  IconButton,
  makeStyles,
  Typography,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import BackupRoundedIcon from "@material-ui/icons/BackupRounded";
import DoneAllRoundedIcon from "@material-ui/icons/DoneAllRounded";
import { useRootStore } from "../../../mobx/context";
import blue from "@material-ui/core/colors/blue";
import grey from "@material-ui/core/colors/grey";
import FileUploadModalComponent from "../fileComponents/fileUploads/fileUploadModalComponent";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[300],
    flexGrow: 1,
    padding: 0,
  },
  menuButton: {},
}));
const FileDirectorySubMenuComponent = observer(() => {
  const classes = useStyles();
  const { FileStore } = useRootStore();

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="space-between"
        direction="row"
        spacing={2}
      >
        <Grid item>
          <Tooltip
            title="Go back to previous directory"
            placement="bottom"
            arrow
          >
            <span>
              <Button
                color="primary"
                variant="outlined"
                className={classes.menuButton}
                onClick={() => {
                  FileStore.goUpOneDirectory();
                }}
                style={{ marginLeft: "10px" }}
                startIcon={<KeyboardBackspaceRoundedIcon />}
              >
                Back
              </Button>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Go to home directory" placement="bottom" arrow>
            <span>
              <Button
                color="primary"
                variant="outlined"
                className={classes.menuButton}
                onClick={() => {
                  FileStore.goToRootDirectory();
                }}
                startIcon={<HomeRoundedIcon />}
              >
                Home Directory
              </Button>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <FileUploadModalComponent />
        </Grid>
        <Grid item style={{ marginLeft: "auto", marginRight: "10px" }}>
          <Tooltip
            title="Select all files in current directory"
            placement="bottom"
            arrow
          >
            <span>
              <Button
                color="primary"
                variant="outlined"
                disabled={FileStore.isEmptyFileDirectory()}
                className={classes.menuButton}
                onClick={() => {
                  FileStore.selectAllFilesInCurrentPath();
                }}
                startIcon={<DoneAllRoundedIcon />}
              >
                Select All
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
});

export default FileDirectorySubMenuComponent;

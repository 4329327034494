import { gql, useQuery } from "@apollo/client";
import { CircularProgress, Grid, List, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import * as React from "react";
import _ from "lodash";
import { useRootStore } from "../../../../mobx/context";
import ArchiveDownloadListItemComponent from "./archiveDownloadListItemComponent";
import EmptyFileFolderListItemComponent from "../../emptyFileFolderListItemComponent";
const USER_ARCHIVES = gql`
  query userArchiveDownloads($accessToken: String!) {
    userArchiveDownloads(accessToken: $accessToken) {
      archives {
        userID
        archiveStatus
        archiveName
        downloadKey
        downloaded
        archiveID
        ttl
      }
    }
  }
`;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
    maxHeight: 500,
    minHeight: 500,
  },
  progressWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    height: "100%",
  },
}));
const ArchiveDownloadListComponent = observer(() => {
  const classes = useStyles();
  const { AuthenticationStore, ArchiveDownloadStore } = useRootStore();
  const { data, loading, error } = useQuery(USER_ARCHIVES, {
    variables: { accessToken: AuthenticationStore.getAccessTokenValue() },
    fetchPolicy: "network-only",
    pollInterval: 1000,
  });
  React.useEffect(() => {
    if (data) {
      ArchiveDownloadStore.mergeNewDataWithOld(
        data.userArchiveDownloads.archives
      );
    }
  }, [data]);
  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <List>
            {loading ? (
              <div className={classes.progressWrapper}>
                <CircularProgress size={90} />
              </div>
            ) : (
              _.map(
                ArchiveDownloadStore.getActiveDownloadObjects(),
                (items) => {
                  return (
                    <ArchiveDownloadListItemComponent
                      key={items.archiveName}
                      archiveID={items.archiveID}
                    />
                  );
                }
              )
            )}
          </List>
        </Grid>
      </Grid>
    </div>
  );
});
export default ArchiveDownloadListComponent;

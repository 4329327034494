import {
  Breadcrumbs,
  Grid,
  LinearProgress,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { KeyboardReturnOutlined } from "@material-ui/icons";
import _ from "lodash";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useRootStore } from "../../../mobx/context";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["200"],
    minHeight: 45,
  },
}));
const FolderDirectoryBreadcrumbComponent = observer(() => {
  const classes = useStyles();
  const { FileStore } = useRootStore();
  const handleClick = (e: any) => {
    const key = `${e.target.childNodes[0].data}`;
    console.log(key);
    let breadCrumbCollection = { path: "/" };
    if (key !== "Home") {
      breadCrumbCollection = FileStore.getBreadcrumb[key];
      console.log(breadCrumbCollection);
    }
    FileStore.goToExactPath(breadCrumbCollection.path);
  };
  const buildLinksForBreadcrumb = () => {
    const breadKey = FileStore.currentPath.split("/");

    if (breadKey.length === 2) {
      return [
        <Link key="homebread" color="inherit" onClick={handleClick}>
          <Typography
            variant="overline"
            style={{ marginLeft: 5, fontSize: 10 }}
          >
            Home
          </Typography>
        </Link>,
      ];
    }
    breadKey.pop();
    breadKey.pop();

    return _.map(breadKey, (key) => {
      return (
        <Link color="inherit" onClick={handleClick}>
          <Typography
            variant="overline"
            style={{ marginLeft: 5, fontSize: 10 }}
          >
            {key === "" ? "Home" : key}
          </Typography>
        </Link>
      );
    });
  };
  return (
    <div className={classes.root}>
      <Grid
        container
        alignContent="center"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Breadcrumbs maxItems={3} aria-label="breadcrumb">
            {buildLinksForBreadcrumb()}
            {FileStore.getCurrentFolderName() === "/" ? (
              <div></div>
            ) : (
              <Link color="primary">
                <Typography
                  variant="overline"
                  style={{ marginLeft: 5, fontSize: 10 }}
                >
                  {FileStore.getCurrentFolderName()}
                </Typography>
              </Link>
            )}
          </Breadcrumbs>
        </Grid>
      </Grid>
    </div>
  );
});

export default FolderDirectoryBreadcrumbComponent;

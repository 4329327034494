import React from "react";
import {
  Grid,
  Button,
  IconButton,
  makeStyles,
  Typography,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import BackupRoundedIcon from "@material-ui/icons/BackupRounded";
import ClearAllRoundedIcon from "@material-ui/icons/ClearAllRounded";
import ArchiveRoundedIcon from "@material-ui/icons/ArchiveRounded";
import { useRootStore } from "../../../mobx/context";
import blue from "@material-ui/core/colors/blue";
import grey from "@material-ui/core/colors/grey";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[300],
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: "10px",
  },
}));
const SelectedFilesSideMenuComponent = observer(() => {
  const classes = useStyles();
  const { FileStore, ArchiveStore } = useRootStore();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="flex-start" direction="row" spacing={1}>
        <Grid item>
          <Tooltip title="Clear All" placement="bottom" arrow>
            <Button
              disabled={ArchiveStore.selectedFilesCount() === 0}
              color="primary"
              variant="outlined"
              className={classes.menuButton}
              onClick={() => {
                ArchiveStore.deselectAllFiles();
              }}
              startIcon={<ClearAllRoundedIcon />}
            >
              Clear Selected
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
});

export default SelectedFilesSideMenuComponent;

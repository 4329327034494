import React from "react";
import { observer } from "mobx-react-lite";
import LoginComponent from "../../components/login/loginComponent";
import { useRootStore } from "../../mobx/context";
import { gql, useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { ILoginResponse } from "../../interfaces/gql/login";
import { navigate } from "@reach/router";
import { AuthenticationStore } from "../../mobx/stores/authenticationStore";
import { useToasts } from "react-toast-notifications";

//gql query for logging a user in via their creds.
const LOGIN_WITH_CREDS = gql`
  query login($username: String!, $password: String!) {
    loginWithCreds(username: $username, password: $password) {
      AccessToken
      ExpiresIn
      RefreshToken
    }
  }
`;

const LoginContainer = observer(() => {
  const rootStore = useRootStore();
  const { AuthenticationStore } = rootStore;
  const { addToast } = useToasts();
  const [loading, setLoading] = React.useState(false);
  const client = useApolloClient();
  const loginUser = async () => {
    setLoading(true);
    client
      .query({
        query: LOGIN_WITH_CREDS,
        variables: {
          username: AuthenticationStore.getUsername,
          password: AuthenticationStore.getPassword,
        },
      })
      .then((response) => {
        const userLoginData = response.data.loginWithCreds as ILoginResponse;
        AuthenticationStore.setAccessTokenExpirationTime(
          userLoginData.ExpiresIn
        );
        AuthenticationStore.setRefreshToken(userLoginData.RefreshToken);
        AuthenticationStore.setAccessToken(userLoginData.AccessToken);
        AuthenticationStore.setUsernameHash(AuthenticationStore.getUsername);
        AuthenticationStore.setUserAuthentication(true);
        AuthenticationStore.setUserFingerPrint(AuthenticationStore.password);
        setLoading(false);
        navigate("/");
      })
      .catch((error) => {
        addToast(error.message.split(":")[1], { appearance: "error" });
        setLoading(false);
      });
  };

  return (
    <LoginComponent
      getUsername={() => AuthenticationStore.getUsername}
      getStoredUsername={() => AuthenticationStore.getStoredUsername}
      setUsername={AuthenticationStore.setUsername}
      getPassword={() => AuthenticationStore.getPassword}
      setPassword={AuthenticationStore.setPassword}
      loading={loading}
      loginUser={loginUser}
    />
  );
});

export default LoginContainer;

import React from "react";
import { AuthenticatedLayout } from "../layouts/authenticatedLayout";
import { Redirect } from "@reach/router";
import { useRootStore } from "../mobx/context";
import { StreamingLayout } from "../layouts/streamingLayout";
import { SharedStreamingLayout } from "../layouts/sharedStreamingLayout";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const rootStore = useRootStore();
  const { AuthenticationStore } = rootStore;
  const { isAuthenticated } = AuthenticationStore;
  return (
    <div>
      {isAuthenticated ? (
        <AuthenticatedLayout>
          <Component {...rest} />
        </AuthenticatedLayout>
      ) : (
        <Redirect from="" to="/login" noThrow />
      )}
    </div>
  );
};
export const SharedStreamingRoute = ({ component: Component, ...rest }) => {
  const rootStore = useRootStore();
  return (
    <div>
      <SharedStreamingLayout>
        <Component {...rest} />
      </SharedStreamingLayout>
    </div>
  );
};
export const StreamingRoute = ({ component: Component, ...rest }) => {
  const rootStore = useRootStore();
  const { AuthenticationStore } = rootStore;
  const { isAuthenticated } = AuthenticationStore;
  return (
    <div>
      {isAuthenticated ? (
        <StreamingLayout>
          <Component {...rest} />
        </StreamingLayout>
      ) : (
        <Redirect from="" to="/login" noThrow />
      )}
    </div>
  );
};

export const PublicRoute = ({ component: Component, ...rest }) => {
  return <Component {...rest} />;
};

import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  makeStyles,
  Paper,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import { useRootStore } from "../../../../mobx/context";
import Alert, { Color } from "@material-ui/lab/Alert";
import { gql, useApolloClient } from "@apollo/client";
import { useToasts } from "react-toast-notifications";
import * as _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    padding: theme.spacing(4),
  },
}));
interface FileUploadComponentProps {
  setUploadFile: (files: FileList) => void;
  handleDialogClose: () => void;
  handleUploadFile: () => void;
  uploadFile?: FileList;
  showBadCsgPathAlert: boolean;
}
const FileUploadComponent: React.FunctionComponent<FileUploadComponentProps> =
  observer(
    ({ handleDialogClose, setUploadFile, uploadFile, showBadCsgPathAlert }) => {
      const classes = useStyles();
      const { FileStore } = useRootStore();
      const { addToast } = useToasts();
      const uploadFileRef = React.useRef<HTMLInputElement>(null);
      const allowedUploadExtensions = ["csg", "png", "csb", "3dm", "pdf"];
      return (
        <React.Fragment>
          <Box hidden={!showBadCsgPathAlert}>
            <Alert severity="error">
              CSG files cannot be uploaded outside of a project directory.
            </Alert>
          </Box>
          <div className={classes.root}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="overline">Upload Path: </Typography>
                <Typography variant="overline" color="primary">
                  {FileStore.getCurrentPath()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="overline">Selected File: </Typography>
                <Typography variant="overline" color="primary">
                  {uploadFile ? uploadFile[0].name : ""}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <input
                  id="file_upload"
                  ref={uploadFileRef}
                  type="file"
                  hidden
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      const filename = e.target.files[0].name;
                      const fileExtension = filename.split(".").pop();
                      if (
                        allowedUploadExtensions.indexOf(fileExtension) === -1
                      ) {
                        addToast(
                          `Only the following file extensions are valid: ${allowedUploadExtensions.join(
                            ", "
                          )}`,
                          {
                            appearance: "error",
                          }
                        );
                        e.target.files = null;
                        handleDialogClose();
                        return;
                      }
                      console.log(e.target.files);
                      setUploadFile(e.target.files);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  style={{ width: "100%" }}
                  variant="outlined"
                  onClick={() => {
                    if (uploadFileRef.current) {
                      uploadFileRef.current.click();
                    }
                  }}
                >
                  Select File
                </Button>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      );
    }
  );

export default FileUploadComponent;

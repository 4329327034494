import {
  Checkbox,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import ClearIcon from "@material-ui/icons/Clear";
import * as React from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import { useRootStore } from "../../../../mobx/context";
type SelectedListItemComponentProps = {
  fileName: string;
  fileSize: number;
  uniqueKey: string;
};
const SelectedListItemComponent: React.FunctionComponent<SelectedListItemComponentProps> =
  observer(({ fileName, fileSize, uniqueKey }) => {
    const { ArchiveStore } = useRootStore();
    return (
      <ListItem role={undefined} dense>
        <ListItemIcon>
          <DescriptionIcon color="secondary" />
        </ListItemIcon>
        <ListItemText
          id={fileName}
          primary={fileName}
          secondary={formatBytes(fileSize)}
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={(e) => {
              e.preventDefault();
              ArchiveStore.deselectFile(uniqueKey);
            }}
          >
            <ClearIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  });

//TODO: move this to some utils place
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export default SelectedListItemComponent;

import { gql, useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useToasts } from "react-toast-notifications";
import { useRootStore } from "../../mobx/context";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { Alert, AlertTitle } from "@material-ui/lab";
import Countdown from "react-countdown";

import dayjs from "dayjs";
declare let appstreamEmbed: any;
declare let AppStream: any;
const CREATE_STREAMING_SESSION = gql`
  query startCsStreamingSession(
    $accessToken: String!
    $username: String!
    $password: String!
  ) {
    startCsStreamingSession(
      accessToken: $accessToken
      username: $username
      password: $password
    ) {
      url
      expiresIn
    }
  }
`;
const CHECK_IN = gql`
  query csStreamingCheckIn($accessToken: String!) {
    csStreamingCheckIn(accessToken: $accessToken) {
      isValidClaim
    }
  }
`;
const USER_HAS_ACITVE_CLAIM = gql`
  query userHasActiveCsStreamingClaim($accessToken: String!) {
    userHasActiveCsStreamingClaim(accessToken: $accessToken) {
      claim
    }
  }
`;
const USER_HAS_ACTIVE_SESSION = gql`
  query userHasActiveSession($accessToken: String!, $username: String!) {
    userHasActiveSession(accessToken: $accessToken, username: $username) {
      activeSession
    }
  }
`;
const StreamingClientComponent = observer(() => {
  const { AuthenticationStore } = useRootStore();
  let appstreamEmbed: any = null;
  const { addToast } = useToasts();
  const client = useApolloClient();
  const [claimValid, setClaimValid] = React.useState(true);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");
  const [closeWindowButtonVisible, setCloseWindowButtonVisible] =
    React.useState(false);
  const [appStreamError, setAppStreamError] = React.useState(false);
  const [appStreamRunning, setAppStreamRunning] = React.useState(false);
  const [timerTime, setTimerTime] = React.useState(0);
  const [timerTimeWasSet, setTimerTimeWasSet] = React.useState(false);
  const [runTimerOnCompleteLogic, setRunTimerOnCompleteLogic] =
    React.useState(true);
  const [initReloadComplete, setInitReloadcomplete] = React.useState(false);
  const [streamingEmbedVisibility, setStreamingEmbedVisibility] =
    React.useState<VisibilityState>("visible");

  //check in, refreshing claim timer
  const { data: checkInData, error: checkInError } = useQuery(CHECK_IN, {
    variables: { accessToken: AuthenticationStore.getAccessTokenValue() },
    fetchPolicy: "network-only",
    pollInterval: 4000,
  });
  if (checkInError) {
    console.log(checkInError);
  }
  //check if user has active claim
  const { data: claimCheckData, error: claimCheckError } = useQuery(
    USER_HAS_ACITVE_CLAIM,
    {
      variables: { accessToken: AuthenticationStore.getAccessTokenValue() },
      fetchPolicy: "network-only",
      pollInterval: 180000,
    }
  );
  //360000
  //if claim is valid start the streaming client, otherwise display error
  React.useEffect(() => {
    if (claimCheckData) {
      const { userHasActiveCsStreamingClaim } = claimCheckData;
      //console.log(`CHCKIN-${new Date()}`, userHasActiveCsStreamingClaim);

      if (userHasActiveCsStreamingClaim.claim !== null && !appStreamRunning) {
        //if we have a valid claim, and haven't started appstream, run the startup process
        setAppStreamRunning(true);
        launchAppStreamSession(timerTimeWasSet);
        setTimerTimeWasSet(true);
      }
      //if we don't have a valid claim destroy the appstream process
      if (userHasActiveCsStreamingClaim.claim === null) {
        setStreamingEmbedVisibility("hidden");
        //destroyAppStreamFrame();
        setAlertMessage(
          "The license was either invalid or expired. Please re-launch the streaming session."
        );
        setAlertTitle("License Invalid");
        setCloseWindowButtonVisible(true);
        setAppStreamError(true);
      }
    }
  }, [claimCheckData, checkInData, checkInError, timerTimeWasSet]);

  const launchAppStreamSession = (timerTimeWasSet: boolean) => {
    client
      .query({
        query: CREATE_STREAMING_SESSION,
        variables: {
          accessToken: AuthenticationStore.getAccessTokenValue(),
          username: AuthenticationStore.getStoredUsername,
          password: AuthenticationStore.getUserFingerPrint,
        },
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        const { startCsStreamingSession: startCsStreamingSession } =
          response.data;
        if (
          startCsStreamingSession.url &&
          startCsStreamingSession.url.length > 0 &&
          claimValid
        ) {
          //destroyAppStreamFrame();
          const userInterfaceConfig = {};
          userInterfaceConfig[AppStream.Embed.Options.HIDDEN_ELEMENTS] = [
            AppStream.Embed.Elements.TOOLBAR,
          ];

          const appstreamOptions = {
            sessionURL: startCsStreamingSession.url,
            userInterfaceConfig: userInterfaceConfig,
          };

          if (appstreamEmbed === null) {
            appstreamEmbed = new AppStream.Embed(
              "appstream-container",
              appstreamOptions
            );
          }

          appstreamEmbed.addEventListener(
            AppStream.Embed.Events.SESSION_ERROR,
            errorCallback
          );

          appstreamEmbed.addEventListener(
            AppStream.Embed.Events.SESSION_STATE_CHANGE,
            updateSessionStateCallback
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setStreamingEmbedVisibility("hidden");
        setAlertMessage(
          "Error connecting to CounterSketch Streaming Client, please relaunch your streaming session."
        );
        setAlertTitle("Connection Error");
        setCloseWindowButtonVisible(true);
        setAppStreamError(true);
      });
  };
  function errorCallback(event) {
    console.log("APPSTREAMERROR", JSON.stringify(event));
    setStreamingEmbedVisibility("hidden");
    setAlertMessage(JSON.stringify(event));
    setAlertTitle("CounterSketch Streaming Error");
    setCloseWindowButtonVisible(true);
    setAppStreamError(true);
  }
  const updateSessionStateCallback = (event) => {
    console.log("APPSTREAM-STATE", JSON.stringify(event));
    if (event.sessionStatus === "Started") {
      addToast("Session Started.", {
        appearance: "success",
      });
    }
  };
  // const destroyAppStreamFrame = () => {
  //   if (appstreamEmbed) {
  //     console.log("DESTROY START");
  //     appstreamEmbed.destroy();
  //     appstreamEmbed = null;

  //     const rootElement = document.getElementById("root");
  //     const oldContainer = document.getElementById("appstream-container");
  //     if (oldContainer.parentElement === rootElement) {
  //       rootElement.removeChild(oldContainer);
  //     } else {
  //       console.log("Undefined parent container");
  //     }
  //     console.log("DESTROY END");
  //   }
  // };

  //console.log(appstreamEmbed);
  return (
    <div id="root" style={{ overflow: "hidden" }}>
      <div
        id="appstream-container"
        style={{
          visibility: streamingEmbedVisibility,
          width: "100vw",
          height: "100vh",
          margin: "auto",
          minWidth: "1024px",
          minHeight: "768px",
        }}
      >
        <Box hidden={streamingEmbedVisibility === "hidden" ? false : true}>
          <Grid
            container
            direction="row"
            alignItems="center"
            alignContent="center"
            justifyContent="space-around"
            style={{
              visibility:
                streamingEmbedVisibility === "visible" ? "collapse" : "visible",
            }}
          >
            <Box hidden={!appStreamError}>
              <Grid item xs={12} style={{ marginBottom: "12px" }}>
                <Alert severity="error">
                  <AlertTitle>{alertTitle}</AlertTitle>
                  {alertMessage}
                </Alert>
              </Grid>

              <Box hidden={!closeWindowButtonVisible}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      window.close();
                    }}
                  >
                    Close Window
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Box>
      </div>
    </div>
  );
});

export default StreamingClientComponent;

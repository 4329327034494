import { Button, Grid, List, makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import _ from "lodash";
import { runInAction } from "mobx";
import { Observer, observer } from "mobx-react-lite";
import * as React from "react";
import { useEffect } from "react";
import { useRootStore } from "../../../../mobx/context";
import DividerWithTextComponent from "../../../dividerWithText/dividerWithTextComponent";

import FileListItemComponent from "../../fileComponents/fileListItem";
import SelectedFilesSideMenuComponent from "../selectedFilesSideMenuComponent";
import { ArchiveNameDialogComponent } from "./archiveNameDialogComponent";
import SelectedListItemComponent from "./selectedListItemComponent";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
    maxHeight: 500,
    minHeight: 500,
  },
  rootGrid: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const SelectedFilesComponent = observer(() => {
  const classes = useStyles();
  const { ArchiveStore } = useRootStore();
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          {ArchiveStore.doesArchiveExceedMaxSize ? (
            <Alert severity="error">
              Selected files exceed maximum archive size, please remove some
              files.
            </Alert>
          ) : (
            <div />
          )}
          <List className={classes.root} dense>
            {buildSelectedFileComponentsList()}
          </List>
        </Grid>
        <Grid item xs={12}>
          <ArchiveNameDialogComponent />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

const buildSelectedFileComponentsList = () => {
  const { ArchiveStore, FileStore } = useRootStore();
  const selectedFileComponents = _.map(ArchiveStore.selectedFileIds, (key) => {
    const fileObject = FileStore.getFileObjectByKey(key);
    return (
      <SelectedListItemComponent
        key={key}
        uniqueKey={key}
        fileName={fileObject.fileName}
        fileSize={fileObject.size}
      />
    );
  });
  return selectedFileComponents;
};
export default SelectedFilesComponent;

import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import * as React from "react";
import GhostContentAPI from "@tryghost/content-api";
import NewsCardComponent, { NewsCardComponentProps } from "./newsCardComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
}));

const NewsListComponent = observer(() => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [posts, setPosts] = React.useState(Array<NewsCardComponentProps>());
  const api = new GhostContentAPI({
    url: "https://blog.gemvision.cloud",
    key: process.env.REACT_APP_GHOST_API_KEY,
    version: "v3",
  });
  React.useEffect(() => {
    api.posts
      .browse({
        limit: 5,
        include: "tags,authors",
        filter: "tag:countersketch",
      })
      .then((posts) => {
        setLoading(false);
        setPosts(posts);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [setPosts, setLoading]);
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {loading ? (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        ) : (
          posts.map((post) => {
            return (
              <NewsCardComponent
                key={post.id}
                id={post.id}
                excerpt={post.excerpt}
                published_at={post.published_at}
                title={post.title}
                html={post.html}
                feature_image={post.feature_image}
              />
            );
          })
        )}
      </Grid>
    </div>
  );
});

export default NewsListComponent;

import { action, makeAutoObservable, observable } from "mobx";
import { RootStore } from "./rootStore";
import dayjs from "dayjs";
import sha256 from "sha256";
import axios from "axios";
import CryptoJS from "crypto-js";
export class AuthenticationStore {
  private root: RootStore;
  private key: string;
  //gql spams getAccessTokenValue, this prevents multiple refresh fetches
  refreshingAccessToken = false;
  username = "";
  password = "";
  usernameHash = "";
  authenticated = false;
  accessTokenExpirationTime: dayjs.Dayjs;
  refreshToken = "";
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.accessTokenExpirationTime = dayjs();
    this.key = "23lk)jwf;l#fj2902398_;ldkfj";
    this.root = rootStore;
  }

  get getUsername(): string {
    return this.username;
  }

  get getStoredUsername(): string {
    return localStorage.getItem("streamingUserName") || "";
  }

  get getUsernameHash(): string {
    return this.usernameHash;
  }

  get getPassword(): string {
    return this.password;
  }

  get isAuthenticated(): boolean {
    return this.authenticated;
  }

  get accessTokenDurationMs(): number {
    return 60000;
  }

  getAccessTokenValue = (): string => {
    if (
      this.accessTokenExpirationTime.isBefore(dayjs()) &&
      !this.refreshingAccessToken
    ) {
      //console.log("Access Token About To Expire");
      this.refreshingAccessToken = true;
      axios({
        url: `${process.env.REACT_APP_GATEWAY_URL}`,
        method: "post",
        data: {
          query: `
        query login($refreshToken: String!) {
          loginWithToken(refreshToken: $refreshToken) {
            AccessToken
            ExpiresIn
            RefreshToken
          }
        }
          `,
          variables: {
            refreshToken: this.refreshTokenValue,
          },
        },
      })
        .then((result) => {
          // console.log(result.data);
          const { loginWithToken } = result.data.data;
          //console.log(loginWithToken);
          this.setAccessToken(loginWithToken.AccessToken);
          this.setAccessTokenExpirationTime(loginWithToken.ExpiresIn);
          this.refreshingAccessToken = false;
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return localStorage.getItem("streamingAccess") || "";
  };

  get refreshTokenValue(): string {
    return this.refreshToken;
  }
  //takes the expiration duration and converts it from seconds to ms.
  setAccessTokenExpirationTime = (duration: number) => {
    //shave off some seconds to refresh before acutal expiration time.
    this.accessTokenExpirationTime = dayjs().add(3000, "second");
    //console.log(this.accessTokenExpirationTime);
  };

  setAccessToken = (tokenValue: string) => {
    //console.log("Access Token Changed....");
    localStorage.setItem("streamingAccess", tokenValue);
    //we need to preload the userName hash here just in case
    this.usernameHash = localStorage.getItem("streamingUsernameHash") || "";
    //this.accessToken = tokenValue;
  };

  setRefreshToken = (tokenValue: string) => {
    //refresh token only gets set on cred login. Add values to localStorage to facilitate logins.
    localStorage.setItem("streamingUserToken", tokenValue);
    localStorage.setItem("streamingUserWasLoggedIn", "true");
    this.refreshToken = tokenValue;
  };

  setUsername = (username: string): void => {
    if (username) {
      this.username = username;
    } else {
      this.username = "";
    }
  };

  setUserFingerPrint = (value: string) => {
    const cipher = CryptoJS.AES.encrypt(value, this.key);
    localStorage.setItem("streamingClientFingerPrint", cipher);
  };

  get getUserFingerPrint() {
    const value = localStorage.getItem("streamingClientFingerPrint");
    const bytes = CryptoJS.AES.decrypt(value, this.key);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  setUsernameHash = (username: string): void => {
    const usernameHash = sha256(username);
    localStorage.setItem("streamingUserName", username);
    localStorage.setItem("streamingUsernameHash", usernameHash);
    this.usernameHash = usernameHash;
  };

  setPassword = (password: string): void => {
    this.password = password;
  };

  setUserAuthentication = (value: boolean) => {
    this.authenticated = value;
  };
  logout = () => {
    this.authenticated = false;
    this.setAccessToken("");
    this.refreshToken = "";
    this.accessTokenExpirationTime = dayjs();
    this.password = "";
    localStorage.setItem("streamingUserToken", "");
    //explicit logout, we remove this.
    localStorage.setItem("streamingUserWasLoggedIn", "false");
  };
}

import { action, makeAutoObservable, runInAction, toJS } from "mobx";
import { RootStore } from "./rootStore";
import * as _ from "lodash";
import sha256 from "sha256";

export type FolderType = {
  folderPath: string;
  folderName: string;
  type: string;
  uniqueKey: string;
  pathKey: string;
};
export type FileType = {
  fileName: string;
  key: string;
  size: number;
  type: string;
  uniqueKey: string;
  selected: boolean;
  lastModified: string;
  downloadProgress: number;
  downloading: boolean;
};
export type DirectoryFolderType = {
  [key: string]: FolderType;
};
export type DirectoryFileType = {
  [key: string]: FileType;
};
export type DirectoryHash = {
  [key: string]: {
    folders: Array<string>;
    files: Array<string>;
  };
};
export type BreadCrumbPathType = {
  [key: string]: { path: string };
};

export class FileStore {
  directoryHash: DirectoryHash;
  directoryFolders: DirectoryFolderType;
  directoryFiles: DirectoryFileType;
  currentPath: string;
  breadcrumb: BreadCrumbPathType;
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.directoryHash = {};
    this.directoryFolders = {};
    this.directoryFiles = {};
    //we start at the root of the s3
    this.currentPath = "/";
    this.breadcrumb = { Home: { path: "/" } };
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }
  resetStore = () => {
    this.directoryHash = {};
    this.directoryFolders = {};
    this.directoryFiles = {};
    //we start at the root of the s3
    this.currentPath = "/";
    this.breadcrumb = { Home: { path: "/" } };
  };
  get getBreadcrumb() {
    return toJS(this.breadcrumb);
  }
  //gets the folder keys from the directory hash, and returns the real folder objects with hash lookup from directoryFolder
  public getFolderObjectsForPath = (path: string) => {
    if (this.directoryHash[path]) {
      const folderKeys = this.directoryHash[path].folders;

      return this.getFolderObjectsFromDirectory(folderKeys);
    }
  };

  public getFileObjectsForPath = (path: string) => {
    if (this.directoryHash[path]) {
      const fileKeys = this.directoryHash[path].files;
      return toJS(this.getFileObjectsFromDirectory(fileKeys));
    }
  };

  public getCurrentPath = () => {
    return this.currentPath;
  };
  //returns the file object for the given uniqueId
  public getFileObjectByKey = (uniqueId: string) => {
    return this.directoryFiles[uniqueId];
  };

  public updateFileObjectInDirectory = (fileObject: FileType) => {
    this.directoryFiles[fileObject.uniqueKey] = fileObject;
  };

  public debugGetDirectoryHash = () => {
    return toJS(this.directoryHash);
  };

  public selectAllFilesInCurrentPath = () => {
    runInAction(() => {
      //get the string list of all the file ids
      const files = this.directoryHash[this.currentPath].files;
      _.forEach(files, (file) => {
        this.rootStore.ArchiveStore.selectFile(file);
      });
    });
  };

  public isEmptyFolderDirectory = () => {
    const directoryContents = this.directoryHash[this.currentPath];
    if (directoryContents?.folders) {
      return directoryContents.folders.length === 0;
    }
    return true;
  };

  public isEmptyFileDirectory = () => {
    const directoryContents = this.directoryHash[this.currentPath];
    if (directoryContents?.files) {
      return directoryContents.files.length === 0;
    }
    return true;
  };

  public updateFileDownloadStatus = (
    uniqueKey: string,
    downloading: boolean,
    downloadProgress?: number
  ) => {
    const fileObject = this.directoryFiles[uniqueKey];
    fileObject.downloading = downloading;
    if (downloadProgress) {
      fileObject.downloadProgress = downloadProgress;
    }
  };

  getCurrentFolderName = () => {
    if (this.currentPath === "/") {
      return "/";
    }
    const path = this.currentPath.split("/");
    path.pop();
    const folderName = path.pop();
    return folderName;
  };

  public goUpOneDirectory = () => {
    //dont go back if we are at root
    if (this.currentPath === "/") {
      return;
    }
    const splitPath = this.currentPath.split("/");
    if (splitPath.length > 0) {
      splitPath.pop();
      splitPath.pop();
    }
    //we were just one folder above root, go back to root.
    if (splitPath.length === 1) {
      this.goToExactPath("/");
    } else {
      //trailing / on paths push empty to have join put it back
      splitPath.push("");
      this.goToExactPath(splitPath.join("/"));
    }
  };

  public goToRootDirectory = () => {
    this.currentPath = "/";
  };

  public goToPath = (newPath: string) => {
    const navigateTo = this.currentPath + newPath;
    //create the directory in directoryHash, it will be populated when the data is fetched.
    this.createDirectoryHashEntryForPath(navigateTo);

    this.goToExactPath(navigateTo);
  };
  //takes a full path and navigates to it.
  public goToExactPath = (path: string) => {
    runInAction(() => {
      this.buildBreadcrumbFromPath(path);
      this.currentPath = path;
    });
  };

  //formats the gql data into the directory hash
  public formatPathsForDirectoryHash = (
    folders: Array<FolderType>,
    files: Array<FileType>
  ) => {
    //create folder hashes.
    folders.forEach((folderData) => {
      const pathKey = this.rootPathExtractor(folderData.folderPath, "folder");
      if (pathKey) {
        this.addToDirectoryHash(pathKey, folderData);
      }
    });
    files.forEach((fileData) => {
      const pathKey = this.rootPathExtractor(fileData.key, "file");
      if (pathKey) {
        this.addToDirectoryHash(pathKey, undefined, fileData);
      }
    });
  };
  // adds the files and folders to the directoryHash, as well as directoryFolders / Files.
  private addToDirectoryHash = (
    pathKey: string,
    folder?: FolderType,
    file?: FileType
  ) => {
    //for simplicity we process files and folders seperate
    if (folder) {
      const newDirectoryFolderName = sha256(folder.folderPath);
      const newDirectoryFolder = {
        ...folder,
        type: "folder",
        uniqueKey: newDirectoryFolderName,
        pathKey: `${folder.folderName}/`,
      };
      this.directoryFolders[newDirectoryFolderName] = newDirectoryFolder;
      if (
        this.directoryHash[pathKey].folders.indexOf(newDirectoryFolderName) ===
        -1
      ) {
        this.directoryHash[pathKey].folders.push(newDirectoryFolderName);
      }
    }
    if (file) {
      // console.log("FILE PATH KEY", pathKey);
      const newDirectoryFileName = sha256(file.key);
      // const fileExtension = file.fileName.split(".").pop();
      // console.log(fileExtension);
      // //if the extension doesn't match the rules, don't add it to the view collections
      // if (
      //   fileExtension !== "csb" &&
      //   fileExtension !== "csg" &&
      //   fileExtension !== "png"
      // ) {
      //   return;
      // }
      // //preview item check
      // // here we pull off the _fav.png and see if there is a corresponding csg file with the same name.
      // // if there is we add the file to the preview of the main csg file.
      // const previewItemKey = file.key.split("_");
      // const isSpi = previewItemKey.pop();
      // //also filter out _fav_spi.png
      // if (
      //   previewItemKey[previewItemKey.length - 1] === "fav" ||
      //   isSpi === "spi.png"
      // ) {
      //   return;
      // }
      // const newPreviewKey = previewItemKey.join("_");
      // console.log(newPreviewKey);
      // const targetFileKey = sha256(`${newPreviewKey}.csg`);
      // const foundFile = this.getFileObjectByKey(targetFileKey);
      // if (foundFile) {
      //   foundFile.previewKey = file.key;
      //   console.log(foundFile);
      //   this.updateFileObjectInDirectory(foundFile);
      //   return;
      // }

      const newDirectoryFile = {
        ...file,
        type: "file",
        uniqueKey: newDirectoryFileName,
        selected: false,
        downloading: false,
        downloadProgress: 0,
      };
      // filter out the print file png from being displayed along with the cpjx file.
      //  it has no general pattern so we just filter the png files from the print path.
      if (
        newDirectoryFile.fileName.includes("png") &&
        file.key.split("/").indexOf("Prints") !== -1
      ) {
        return;
      }

      this.directoryFiles[newDirectoryFileName] = newDirectoryFile;
      if (
        this.directoryHash[pathKey].files.indexOf(newDirectoryFileName) === -1
      ) {
        this.directoryHash[pathKey].files.push(newDirectoryFileName);
      }
    }
  };
  //gets the direct root path of files and folders.
  private rootPathExtractor = (path: string, fileType: string) => {
    const rootPath = `user/custom/${this.rootStore.AuthenticationStore.getUsernameHash}/`;
    if (fileType === "folder") {
      const trimmedPath = _.trimStart(path, rootPath);
      const newPath = _.split(trimmedPath, "/");

      // if newpath is empty, we are are at the root.
      if (this.currentPath === "/") {
        //force directory creation on root.
        this.createDirectoryHashEntryForPath("/");
        return "/";
      }
      newPath.pop();
      newPath.pop();
      const pathKey = _.join(newPath, "/");
      const finalPathKey = `/${pathKey}/`;
      return finalPathKey;
    }
    if (fileType === "file") {
      const trimmedPath = _.trimStart(path, rootPath);
      const newPath = _.split(trimmedPath, "/");
      newPath.pop();
      //this handles files at the root level, just return root
      if (newPath.length === 0) {
        return `/`;
      }
      const pathKey = _.join(newPath, "/");
      return `/${pathKey}/`;
    }
  };

  //Fetches all the unique keys and returns the real folder object
  private getFolderObjectsFromDirectory = (folders: Array<string>) => {
    const directoryFolderObjects = _.map(folders, (folder) => {
      return this.directoryFolders[folder];
    });
    return toJS(directoryFolderObjects);
  };

  //Fetches all the unique keys and returns the real folder object
  private getFileObjectsFromDirectory = (files: Array<string>) => {
    const directoryFolderObjects = _.map(files, (file) => {
      return this.directoryFiles[file];
    });
    return toJS(directoryFolderObjects);
  };
  //creates a key for the path that will contain all of the directory hash files and folders
  private createDirectoryHashEntryForPath = (path: string) => {
    if (!this.directoryHash[path]) {
      const newKey = {
        folders: Array<string>(),
        files: Array<string>(),
      };
      this.directoryHash[path] = newKey;
    }
  };

  private buildBreadcrumbFromPath = (path: string) => {
    if (this.currentPath === "/") {
      this.breadcrumb = { Home: { path: "/" } };
    } else {
      const breadCrumbPath = this.currentPath.split("/");
      breadCrumbPath.pop();

      this.breadcrumb[`${this.getCurrentFolderName()?.split("/").join("")}`] = {
        path: `${breadCrumbPath.join("/")}/`,
      };
    }
  };
}

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  CircularProgress,
  Grid,
  List,
  makeStyles,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import { autorun, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { FunctionComponent, useEffect } from "react";
import { useRootStore } from "../../../mobx/context";
import { FolderType } from "../../../mobx/stores/fileStore";
import DividerWithTextComponent from "../../dividerWithText/dividerWithTextComponent";
import FullPageLoading from "../../fullPageLoading/fullPageLoading";
import FileDirectoryTopMenuComponent from "../subMenus/fileDirectorySubMenuComponent";
import FileListItemComponent from "../fileComponents/fileListItem";
import FolderListItemComponent from "./folderListItem";
import EmptyFileFolderListItemComponent from "../emptyFileFolderListItemComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
    maxHeight: 500,
    minHeight: 500,
    backgroundColor: theme.palette.common.white,
  },
  progressWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    height: "100%",
  },
}));
type FolderListItemComponentProps = {
  loading: boolean;
};
const FolderDirectoryListComponent: FunctionComponent<FolderListItemComponentProps> =
  observer(({ loading }) => {
    const classes = useStyles();

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.root}>
              {loading ? (
                <div className={classes.progressWrapper}>
                  <CircularProgress size={90} />
                </div>
              ) : (
                <List dense>{createFilesAndFoldersFromPath()}</List>
              )}
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  });

const createFilesAndFoldersFromPath = () => {
  const { FileStore } = useRootStore();
  if (FileStore.isEmptyFolderDirectory()) {
    return [
      <EmptyFileFolderListItemComponent
        key="emptyFile"
        title={"No Sub-Folders In Directory"}
        message={""}
        subMessage={
          "Looks like there are no sub-folders located in this directory."
        }
      />,
    ];
  }
  const folders = _.map(
    FileStore.getFolderObjectsForPath(FileStore.currentPath),
    (values) => {
      return (
        <FolderListItemComponent
          key={values.uniqueKey}
          folderName={values.folderName}
          handleClick={(e) => {
            e.preventDefault();
            FileStore.goToPath(values.pathKey);
          }}
        />
      );
    }
  );

  return [...folders];
};

export default FolderDirectoryListComponent;

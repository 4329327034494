import {
  Checkbox,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import BackupRoundedIcon from "@material-ui/icons/BackupRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useRootStore } from "../../mobx/context";
import FileUploadModalComponent from "./fileComponents/fileUploads/fileUploadModalComponent";
const useStyles = makeStyles((theme) => ({
  listItemRoot: {},
  inline: {
    display: "inline",
  },
  title: {
    textAlign: "center",
  },
}));
type EmptyFileFolderListItemComponentProps = {
  title: string;
  message: string;
  subMessage: string;
  displayUploadButton?: boolean;
};
const EmptyFileFolderListItemComponent: React.FunctionComponent<EmptyFileFolderListItemComponentProps> =
  observer(({ message, title, subMessage, displayUploadButton }) => {
    const { FileStore, ArchiveStore } = useRootStore();
    const classes = useStyles();
    return (
      <ListItem key={title} role={undefined} className={classes.listItemRoot}>
        <ListItemText
          id={message}
          primary={
            <React.Fragment>
              <Typography
                component="span"
                variant="overline"
                className={classes.title}
                color="primary"
              >
                {title}
              </Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {message}
              </Typography>
              {subMessage}
            </React.Fragment>
          }
        />
        {displayUploadButton ? (
          <ListItemSecondaryAction>
            <FileUploadModalComponent />
          </ListItemSecondaryAction>
        ) : (
          <React.Fragment />
        )}
      </ListItem>
    );
  });

export default EmptyFileFolderListItemComponent;
